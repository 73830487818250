import { z } from 'zod';
import dayjs from 'dayjs';
import {
  ActionActivityTypeEnum,
  AdjustmentBasis,
  AlaeAggregateTreatmentOption,
  ArchiveInput,
  AuditQuestionResponseInput,
  AuditScore,
  AuditSection,
  AutoBiOptionEnum,
  AutoFleetSummaryVariant,
  AutoHackingCoverageOptionEnum,
  AutoLargeLossRunInput,
  AutoLiabilityDefenseExpenseTreatmentsEnum,
  AutoLossRunInput,
  AutoModelYearEnum,
  AutoOcnStatedAmountEnum,
  AutoRadiusEnum,
  AutoSecondaryClassDetailPrivateClassDetailEnum,
  AutoSecondaryClassPrivateClassEnum,
  AutoSizeClassEnum,
  AutoStatedAmountEnum,
  AutoUimbiOptionEnum,
  AutoUmbiOptionEnum,
  AutoVehicleInput,
  AutoVehicleUseEnum,
  AvailableFormsInput,
  BatchGetOrganizationMembersInput,
  BatchGetUnderlyingPoliciesInput,
  BusinessType,
  CaseStudiesInput,
  ClaimCarrier,
  ClaimCoverage,
  ClaimLineOfBusiness,
  ClaimStatus,
  CollisionDeductibleCostOfHireBasisObjectValueEnum,
  CollisionDeductibleOptionsObjectValueEnum,
  CombinedSingleLimitOptionsObjectValueEnum,
  CommentActivityTypeEnum,
  ComparisonSentiment,
  ComprehensiveDeductibleCostOfHireBasisObjectValueEnum,
  ComprehensiveDeductibleOptionsObjectValueEnum,
  ComprehensiveOptionsObjectValueEnum,
  ContractorType,
  CostOfHireVariant,
  CreateCommentActivity,
  CreateCommentQuoteActivityInput,
  CreateFileInput,
  CreateFormGroupInput,
  CreateFormPdfInput,
  CreateOrganizationInput,
  CreateOrganizationIntegrationInput,
  CreateOrganizationLocationInput,
  CreateOrganizationUserInput,
  CreatePolicyChangeEndorsementInput,
  CreatePolicyInput,
  CreatePolicyholderInput,
  CreatePrimaryAutoCoverageOptionInput,
  CreateProcoreUsageInput,
  CreateQuoteInput,
  CreateScheduleRatings,
  CreateScheduleRatingsInput,
  CreateUnderlyingPolicyInput,
  DataFillMapInput,
  DeclinedReason,
  DeductibleTypeOptionsEnum,
  DefaultAutoDataInput,
  DeleteFormPdfInput,
  DeleteUnderlyingPolicyInput,
  DuplicateFormPdfInput,
  EmployeeBenefitLiabilityLimitValueEnum,
  EmployeeBenefitLiabilityNumberOfEmployeesValueEnum,
  EventInput,
  ExcessAutoCoverageOptionsIdInput,
  ExcessAutoCoverageOptionsInput,
  ExcessAutoVehicleV2IdInput,
  ExcessAutoVehicleV2Input,
  ExcessIsoClass,
  ExclusionsInput,
  ExpectedPayrollRiskTierInput,
  ExposureIdInput,
  ExposureType,
  FileFileType,
  FileObjectType,
  FileSource,
  FileType,
  FileTypeObject,
  FormPdfsInput,
  FormStatus,
  FormVendor,
  GeneralLiabilityBuildoutScheduleInput,
  GeneralLiabilityDefenseExpenseTreatmentsEnum,
  HiredAutosCoverageEnum,
  HiredOwnedEnum,
  IdInput,
  IndicatedLostReason,
  InsightComparison,
  InsightsLinkResponse,
  IntegrationLocations,
  Integrations,
  InvoiceStatus,
  JurisdictionRiskType,
  LargeLossRunInput,
  LargeLossRunPhysDamRowInput,
  LargeLossRunRowInput,
  LiablityDeductibleOptionsObjectValueEnum,
  LineOfBusiness,
  LossRateSelectionTypeEnum,
  LossRunInput,
  LossRunParsingAsyncRunStatus,
  LossRunParsingAsyncSource,
  LossRunPhysDamInput,
  LossRunVariant,
  MutationAddressAgentSyncCheckInput,
  MutationClearPolicyInput,
  MutationCreateFileWithUnderlyingPoliciesInput,
  MutationCreateGoogleSessionInput,
  MutationCreateGraySessionInput,
  MutationCreateInvoiceInput,
  MutationCreatePricingExcelInput,
  MutationCreateProfitAndLossReportInput,
  MutationCreateSessionInput,
  MutationCreateUnderlyingPoliciesInput,
  MutationCreateVerificationRequestInput,
  MutationDeleteExposureNoteInput,
  MutationDownloadPricingExcelInput,
  MutationGenerateLicensePdfInput,
  MutationLoginInput,
  MutationRenewNonIssuedPolicyInput,
  MutationRunLicenseChecksInput,
  MutationRunOfacClearanceInput,
  MutationUpdateDefaultAutoDataInput,
  MutationUpdateInvoiceInput,
  MutationUpdateSelectedContainmentFactorInput,
  MutationUpdateUserInput,
  MutationUpsertExposureNoteInput,
  Naics2017SixDigitCodes,
  NumberOfTrainsEnum,
  OcpLimitEnum,
  OcpTypeOfOperationsEnum,
  OfacStatus,
  OrganizationMemberRole,
  OrganizationType,
  PaginatedPolicyInput,
  PaymentPlan,
  PayrollInput,
  PdfType,
  PolicyHoldersV3Input,
  PolicyProduct,
  PremiumClass,
  PricingLineItemType,
  PrimaryAutoCostOfHireInput,
  PrimaryAutoCoverageOptionsInput,
  PrimaryAutoIndividualRiskRatingSurveyInput,
  PrimaryAutoVehicleInputV2,
  PrimaryGeneralLiabilityIndividualRiskRatingSurveyInput,
  PrimaryUnderwritingAdjustmentsInput,
  Priority,
  ProgramType,
  ProjectGeneralAggregateValueEnum,
  QueryAutoExposureInput,
  QueryDefaultAutoDataInput,
  QueryDefaultAutoZipCodesByMostCommonPayrollTerritoryInput,
  QueryExposureNotesForQuoteInput,
  QueryHasNpnsInput,
  QueryHqZipCodeInput,
  QueryLicenseChecksInput,
  QueryOrganizationLocationsInput,
  QueryPolicyClearanceResultsInput,
  QueryUnderlyingPolicyFilesInput,
  QuoteActivityType,
  QuoteDetailIdInput,
  QuoteIdInput,
  QuotePdfStatus,
  QuoteStatus,
  QuotedLostReason,
  RailroadLimitEnum,
  RailroadTypeOfOperationsEnum,
  ReasonForTechDataAbsence,
  RentalReimbursementDaysObjectValueEnum,
  RentalReimbursementObjectValueEnum,
  RevenueType,
  RiskTierAutoModelInput,
  SafeOrganizationIntegrationPayload,
  ScheduleRatingTypes,
  SeedVehiclesAndCoveragesInput,
  SelectedFormsInput,
  SelfAuditStatus,
  SelfAuditStatusOptions,
  ShepherdLine,
  ShepsyInput,
  SortOrder,
  SortSalesforce,
  State,
  StateExceptionRulesEditInput,
  StateExceptionRulesInput,
  SubcontractedCostVariant,
  SubcontractedInput,
  SubcontractedIsoClass,
  SubjectivityInputObject,
  SubjectivityType,
  TierEnum,
  TimePeriodEnum,
  TowAndLaborCostObjectValueEnum,
  TriaOption,
  TypeOfVehicleEnum,
  UnderlyingPolicyLimitInput,
  UnderlyingPolicyLimitType,
  UnderlyingPolicyType,
  UnmannedAircraftInput,
  UnmannedAircraftMaximumTakeOffWeightValueEnum,
  UnmannedAircraftOwnershipAndOperationValueEnum,
  UnmannedAircraftPlaceOfOperationModifiersValueEnum,
  UnmannedAircraftUsageModifiersEnum,
  UnmannedAircraftVariant,
  UpdateDefaultAutoDataInput,
  UpdateExcessAutoCoverageOptionInput,
  UpdateExcessAutoCoverageOptionsInput,
  UpdateExcessAutoExposureInput,
  UpdateExcessAutoVehicleV2Input,
  UpdateExcessGeneralLiabilityExposureInput,
  UpdateFileInput,
  UpdateFormGroupInput,
  UpdateFormPdfInput,
  UpdateFormPdfTagsInput,
  UpdateOrganizationInput,
  UpdateOrganizationLocationInput,
  UpdateOrganizationUserInput,
  UpdatePolicyChangeEndorsementInput,
  UpdatePolicyInput,
  UpdatePolicyholderInput,
  UpdatePrimaryAutoCoverageOptionInput,
  UpdatePrimaryAutoExposureInput,
  UpdatePrimaryAutoVehiclesInput,
  UpdatePrimaryGeneralLiabilityExposureInput,
  UpdatePrimaryOcpExposure,
  UpdatePrimaryRailroadCoverageInput,
  UpdateProcoreUsageInput,
  UpdateProjectExcessGeneralLiabilityExposureInput,
  UpdateProjectPrimaryAutoExposureInput,
  UpdateProjectPrimaryGeneralLiabilityExposure,
  UpdateQuoteInput,
  UpdateQuoteSelectionInput,
  UpdateQuoteStatusInput,
  UpdateSelectedFormInput,
  UpdateSelectedFormsInput,
  UpdateShepsyFeedbackInput,
  UpdateSubjectivitiesInput,
  UpdateUnderlyingPolicyInput,
  UpdateWorkersCompensationExposureInput,
  UploadAndParseLossRunFileToClaimsInput,
  UserRole,
  UsersInput,
  VehicleFleetInput,
  VehicleTypesEnum,
} from './types.generated';

type Properties<T> = Required<{
  [K in keyof T]: z.ZodType<T[K], any, T[K]>;
}>;

type definedNonNullAny = {};

export const isDefinedNonNullAny = (v: any): v is definedNonNullAny =>
  v !== undefined && v !== null;

export const definedNonNullAnySchema = z.any().refine((v) => isDefinedNonNullAny(v));

export const ActionActivityTypeEnumSchema = z.nativeEnum(ActionActivityTypeEnum);

export const AdjustmentBasisSchema = z.nativeEnum(AdjustmentBasis);

export const AlaeAggregateTreatmentOptionSchema = z.nativeEnum(AlaeAggregateTreatmentOption);

export function ArchiveInputSchema(): z.ZodObject<Properties<ArchiveInput>> {
  return z.object<Properties<ArchiveInput>>({
    archivedAt: z.string().nullish(),
    id: z.string(),
  });
}

export function AuditQuestionResponseInputSchema(): z.ZodObject<
  Properties<AuditQuestionResponseInput>
> {
  return z.object<Properties<AuditQuestionResponseInput>>({
    auditQuestionId: z.number(),
    comment: z.string().nullish(),
    score: z.string().nullish(),
  });
}

export const AuditScoreSchema = z.nativeEnum(AuditScore);

export const AuditSectionSchema = z.nativeEnum(AuditSection);

export const AutoBiOptionEnumSchema = z.nativeEnum(AutoBiOptionEnum);

export const AutoFleetSummaryVariantSchema = z.nativeEnum(AutoFleetSummaryVariant);

export const AutoHackingCoverageOptionEnumSchema = z.nativeEnum(AutoHackingCoverageOptionEnum);

export function AutoLargeLossRunInputSchema(): z.ZodObject<Properties<AutoLargeLossRunInput>> {
  return z.object<Properties<AutoLargeLossRunInput>>({
    accidentDate: z.preprocess(
      // @ts-ignore
      (value: Date) => dayjs(value).format('YYYY-MM-DD'),
      z.string()
    ),
    alaeOutstanding: z.number(),
    effectiveDate: z.preprocess(
      // @ts-ignore
      (value: Date) => dayjs(value).format('YYYY-MM-DD'),
      z.string()
    ),
    expirationDate: z.preprocess(
      // @ts-ignore
      (value: Date) => dayjs(value).format('YYYY-MM-DD'),
      z.string()
    ),
    indemnityOutstanding: z.number(),
    paidAlae: z.number(),
    paidIndemnity: z.number(),
  });
}

export const AutoLiabilityDefenseExpenseTreatmentsEnumSchema = z.nativeEnum(
  AutoLiabilityDefenseExpenseTreatmentsEnum
);

export function AutoLossRunInputSchema(): z.ZodObject<Properties<AutoLossRunInput>> {
  return z.object<Properties<AutoLossRunInput>>({
    alaeOutstanding: z.number(),
    effectiveDate: z.preprocess(
      // @ts-ignore
      (value: Date) => dayjs(value).format('YYYY-MM-DD'),
      z.string()
    ),
    evaluationDate: z.preprocess(
      // @ts-ignore
      (value: Date) => dayjs(value).format('YYYY-MM-DD'),
      z.string()
    ),
    expirationDate: z.preprocess(
      // @ts-ignore
      (value: Date) => dayjs(value).format('YYYY-MM-DD'),
      z.string()
    ),
    exposureId: z.string().nullish(),
    historicalExposure: z.number(),
    indemnityOutstanding: z.number(),
    nonZeroClaimCount: z.number(),
    paidAlae: z.number(),
    paidIndemnity: z.number(),
    totalClaimCount: z.number(),
  });
}

export const AutoModelYearEnumSchema = z.nativeEnum(AutoModelYearEnum);

export const AutoOcnStatedAmountEnumSchema = z.nativeEnum(AutoOcnStatedAmountEnum);

export const AutoRadiusEnumSchema = z.nativeEnum(AutoRadiusEnum);

export const AutoSecondaryClassDetailPrivateClassDetailEnumSchema = z.nativeEnum(
  AutoSecondaryClassDetailPrivateClassDetailEnum
);

export const AutoSecondaryClassPrivateClassEnumSchema = z.nativeEnum(
  AutoSecondaryClassPrivateClassEnum
);

export const AutoSizeClassEnumSchema = z.nativeEnum(AutoSizeClassEnum);

export const AutoStatedAmountEnumSchema = z.nativeEnum(AutoStatedAmountEnum);

export const AutoUimbiOptionEnumSchema = z.nativeEnum(AutoUimbiOptionEnum);

export const AutoUmbiOptionEnumSchema = z.nativeEnum(AutoUmbiOptionEnum);

export function AutoVehicleInputSchema(): z.ZodObject<Properties<AutoVehicleInput>> {
  return z.object<Properties<AutoVehicleInput>>({
    exposureId: z.string().nullish(),
    modelYear: z.string(),
    ocnStatedAmount: z.string(),
    radius: z.string().nullish(),
    secondaryClassDetailPrivateClassDetail: z.string(),
    secondaryClassPrivateClass: z.string(),
    sizeClass: z.string(),
    state: StateSchema,
    statedAmount: z.string(),
    typeOfVehicle: z.string(),
    use: z.string(),
    vin: z.string().nullish(),
    zipCode: z.string(),
  });
}

export const AutoVehicleUseEnumSchema = z.nativeEnum(AutoVehicleUseEnum);

export function AvailableFormsInputSchema(): z.ZodObject<Properties<AvailableFormsInput>> {
  return z.object<Properties<AvailableFormsInput>>({
    quoteId: z.string(),
  });
}

export function BatchGetOrganizationMembersInputSchema(): z.ZodObject<
  Properties<BatchGetOrganizationMembersInput>
> {
  return z.object<Properties<BatchGetOrganizationMembersInput>>({
    organizationId: z.string().nullish(),
  });
}

export function BatchGetUnderlyingPoliciesInputSchema(): z.ZodObject<
  Properties<BatchGetUnderlyingPoliciesInput>
> {
  return z.object<Properties<BatchGetUnderlyingPoliciesInput>>({
    quoteId: z.string(),
  });
}

export const BusinessTypeSchema = z.nativeEnum(BusinessType);

export function CaseStudiesInputSchema(): z.ZodObject<Properties<CaseStudiesInput>> {
  return z.object<Properties<CaseStudiesInput>>({
    policyholderId: z.string(),
  });
}

export const ClaimCarrierSchema = z.nativeEnum(ClaimCarrier);

export const ClaimCoverageSchema = z.nativeEnum(ClaimCoverage);

export const ClaimLineOfBusinessSchema = z.nativeEnum(ClaimLineOfBusiness);

export const ClaimStatusSchema = z.nativeEnum(ClaimStatus);

export const CollisionDeductibleCostOfHireBasisObjectValueEnumSchema = z.nativeEnum(
  CollisionDeductibleCostOfHireBasisObjectValueEnum
);

export const CollisionDeductibleOptionsObjectValueEnumSchema = z.nativeEnum(
  CollisionDeductibleOptionsObjectValueEnum
);

export const CombinedSingleLimitOptionsObjectValueEnumSchema = z.nativeEnum(
  CombinedSingleLimitOptionsObjectValueEnum
);

export const CommentActivityTypeEnumSchema = z.nativeEnum(CommentActivityTypeEnum);

export const ComparisonSentimentSchema = z.nativeEnum(ComparisonSentiment);

export const ComprehensiveDeductibleCostOfHireBasisObjectValueEnumSchema = z.nativeEnum(
  ComprehensiveDeductibleCostOfHireBasisObjectValueEnum
);

export const ComprehensiveDeductibleOptionsObjectValueEnumSchema = z.nativeEnum(
  ComprehensiveDeductibleOptionsObjectValueEnum
);

export const ComprehensiveOptionsObjectValueEnumSchema = z.nativeEnum(
  ComprehensiveOptionsObjectValueEnum
);

export const ContractorTypeSchema = z.nativeEnum(ContractorType);

export const CostOfHireVariantSchema = z.nativeEnum(CostOfHireVariant);

export function CreateCommentActivitySchema(): z.ZodObject<Properties<CreateCommentActivity>> {
  return z.object<Properties<CreateCommentActivity>>({
    content: z.string(),
  });
}

export function CreateCommentQuoteActivityInputSchema(): z.ZodObject<
  Properties<CreateCommentQuoteActivityInput>
> {
  return z.object<Properties<CreateCommentQuoteActivityInput>>({
    activity: CreateCommentActivitySchema(),
    quoteId: z.string(),
    userId: z.string().nullish(),
  });
}

export function CreateFileInputSchema(): z.ZodObject<Properties<CreateFileInput>> {
  return z.object<Properties<CreateFileInput>>({
    file: z.unknown().nullish(),
    policyId: z.string().nullish(),
    quoteId: z.string().nullish(),
    type: FileTypeSchema,
  });
}

export function CreateFormGroupInputSchema(): z.ZodObject<Properties<CreateFormGroupInput>> {
  return z.object<Properties<CreateFormGroupInput>>({
    formVendor: FormVendorSchema,
    name: z.string(),
  });
}

export function CreateFormPdfInputSchema(): z.ZodObject<Properties<CreateFormPdfInput>> {
  return z.object<Properties<CreateFormPdfInput>>({
    active: z.boolean(),
    category: z.string().nullish(),
    defaultStatus: FormStatusSchema,
    file: z.unknown().nullish(),
    formGroupId: z.string(),
    formGroupInsertionIndex: z.number(),
    formNumber: z.string(),
    formType: z.string(),
    googleDocUrl: z.string().nullish(),
    hasEndorsementNumber: z.boolean(),
    isPdf: z.boolean().nullish(),
    lineOfBusiness: LineOfBusinessSchema.nullish(),
    notes: z.string().nullish(),
    stateExceptionRules: z.array(z.lazy(() => StateExceptionRulesInputSchema())).nullish(),
    stateExceptions: z.array(z.string()).nullish(),
    tria: TriaOptionSchema,
  });
}

export function CreateOrganizationInputSchema(): z.ZodObject<Properties<CreateOrganizationInput>> {
  return z.object<Properties<CreateOrganizationInput>>({
    city: z.string().nullish(),
    einNumber: z.string().nullish(),
    logoUrl: z.string().nullish(),
    name: z.string(),
    npn: z.string().nullish(),
    parentOrganizationName: z.string().nullish(),
    postalCode: z.string().nullish(),
    salesforceRecordId: z.string().nullish(),
    state: StateSchema.nullish(),
    street: z.string().nullish(),
    street2: z.string().nullish(),
    type: OrganizationTypeSchema,
  });
}

export function CreateOrganizationIntegrationInputSchema(): z.ZodObject<
  Properties<CreateOrganizationIntegrationInput>
> {
  return z.object<Properties<CreateOrganizationIntegrationInput>>({
    accessToken: z.string(),
    provider: z.string(),
    providerOrganizationId: z.string().nullish(),
    providerOrganizationName: z.string().nullish(),
    providerUserEmail: z.string().nullish(),
    providerUserId: z.string(),
    refreshToken: z.string().nullish(),
    tokenCreatedAt: z.number(),
    tokenExpiresIn: z.number().nullish(),
    tokenType: z.string().nullish(),
  });
}

export function CreateOrganizationLocationInputSchema(): z.ZodObject<
  Properties<CreateOrganizationLocationInput>
> {
  return z.object<Properties<CreateOrganizationLocationInput>>({
    address1: z.string().nullish(),
    address2: z.string().nullish(),
    assigneeId: z.string().nullish(),
    city: z.string().nullish(),
    excessSurplusLinesPaidByPartner: z.boolean().nullish(),
    name: z.string(),
    organizationId: z.string(),
    salesforceRecordId: z.string().nullish(),
    state: StateSchema.nullish(),
    zipcode: z.string().nullish(),
  });
}

export function CreateOrganizationUserInputSchema(): z.ZodObject<
  Properties<CreateOrganizationUserInput>
> {
  return z.object<Properties<CreateOrganizationUserInput>>({
    email: z.string().email(),
    firstName: z.string(),
    lastName: z.string(),
    npn: z.string().nullish(),
    organizationId: z.string(),
    organizationLocationId: z.string().nullish(),
    role: OrganizationMemberRoleSchema,
    salesforceRecordId: z.string().nullish(),
  });
}

export function CreatePolicyChangeEndorsementInputSchema(): z.ZodObject<
  Properties<CreatePolicyChangeEndorsementInput>
> {
  return z.object<Properties<CreatePolicyChangeEndorsementInput>>({
    brokerCommissionPercentage: z.number(),
    effectiveDate: z.preprocess(
      // @ts-ignore
      (value: Date) => dayjs(value).format('YYYY-MM-DD'),
      z.string()
    ),
    employersLiabilityFeePercentage: z.number(),
    frontingFeePercentage: z.number(),
    issuedDate: z.preprocess(
      // @ts-ignore
      (value: Date) => dayjs(value).format('YYYY-MM-DD'),
      z.string()
    ),
    name: z.string(),
    premium: z.number(),
    profitLoadFeePercentage: z.number(),
    quoteId: z.string(),
    shepherdMgaFeePercentage: z.number(),
    totalExpenseLoadPercentage: z.number(),
    tpaFeePercentage: z.number(),
    triaFeePercentage: z.number(),
  });
}

export function CreatePolicyInputSchema(): z.ZodObject<Properties<CreatePolicyInput>> {
  return z.object<Properties<CreatePolicyInput>>({
    assigneeId: z.string().nullish(),
    brokerOrganizationMemberId: z.string().nullish(),
    contractorId: z.string().nullish(),
    effectiveDate: z.preprocess(
      // @ts-ignore
      (value: Date) => dayjs(value).format('YYYY-MM-DD'),
      z.string()
    ),
    expirationDate: z.preprocess(
      // @ts-ignore
      (value: Date) => dayjs(value).format('YYYY-MM-DD'),
      z.string()
    ),
    expiringEffectiveDate: z.preprocess(
      // @ts-ignore
      (value: Date) => dayjs(value).format('YYYY-MM-DD'),
      z.string()
    ),
    exposures: z.array(ExposureTypeSchema),
    licensedUnderwriterId: z.string().nullish(),
    name: z.string(),
    namedInsureds: z.array(z.string()).nullish(),
    policyNumber: z.string().nullish(),
    policyholderId: z.string(),
    product: PolicyProductSchema,
    programType: ProgramTypeSchema.nullish(),
    quoteSelection: z.lazy(() => UpdateQuoteSelectionInputSchema()),
    submissionAt: z.string().nullish(),
  });
}

export function CreatePolicyholderInputSchema(): z.ZodObject<Properties<CreatePolicyholderInput>> {
  return z.object<Properties<CreatePolicyholderInput>>({
    address1: z.string().nullish(),
    address2: z.string().nullish(),
    city: z.string().nullish(),
    country: z.string().nullish(),
    fein: z.string().nullish(),
    hasAutodesk: z.boolean().nullish(),
    insuredContactEmail: z.string().email().nullish(),
    insuredContactName: z.string().nullish(),
    name: z.string(),
    state: StateSchema,
    website: z.string().nullish(),
    zipcode: z.string().nullish(),
  });
}

export function CreatePrimaryAutoCoverageOptionInputSchema(): z.ZodObject<
  Properties<CreatePrimaryAutoCoverageOptionInput>
> {
  return z.object<Properties<CreatePrimaryAutoCoverageOptionInput>>({
    exposureId: z.string(),
    isProject: z.boolean(),
    state: z.string(),
  });
}

export function CreateProcoreUsageInputSchema(): z.ZodObject<Properties<CreateProcoreUsageInput>> {
  return z.object<Properties<CreateProcoreUsageInput>>({
    adoptionDate: z
      .preprocess(
        // @ts-ignore
        (value: Date) => dayjs(value).format('YYYY-MM-DD'),
        z.string()
      )
      .nullish(),
    annualRevenue: z.number().nullish(),
    coreToolsEngagement: z.number().nullish(),
    csEngagement: z.number().nullish(),
    overallUsage: z.number().nullish(),
    policyholderId: z.string(),
    projectFinancials: z.number().nullish(),
    projectManagement: z.number().nullish(),
    qualitySafety: z.number().nullish(),
    totalActiveUsers: z.number().nullish(),
  });
}

export function CreateQuoteInputSchema(): z.ZodObject<Properties<CreateQuoteInput>> {
  return z.object<Properties<CreateQuoteInput>>({
    exposures: z.array(ExposureTypeSchema),
    name: z.string(),
    policyId: z.string(),
    quoteSelection: z.lazy(() => UpdateQuoteSelectionInputSchema()),
  });
}

export function CreateScheduleRatingsSchema(): z.ZodObject<Properties<CreateScheduleRatings>> {
  return z.object<Properties<CreateScheduleRatings>>({
    appliedModification: z.number(),
    identifier: z.string(),
    rationale: z.string(),
  });
}

export function CreateScheduleRatingsInputSchema(): z.ZodObject<
  Properties<CreateScheduleRatingsInput>
> {
  return z.object<Properties<CreateScheduleRatingsInput>>({
    exposureType: ScheduleRatingTypesSchema,
    quoteId: z.string(),
    scheduleRatings: z.array(CreateScheduleRatingsSchema()),
  });
}

export function CreateUnderlyingPolicyInputSchema(): z.ZodObject<
  Properties<CreateUnderlyingPolicyInput>
> {
  return z.object<Properties<CreateUnderlyingPolicyInput>>({
    attachmentOrderRank: z.number(),
    displayType: z.string(),
    effectiveDate: z.preprocess(
      // @ts-ignore
      (value: Date) => dayjs(value).format('YYYY-MM-DD'),
      z.string()
    ),
    expirationDate: z.preprocess(
      // @ts-ignore
      (value: Date) => dayjs(value).format('YYYY-MM-DD'),
      z.string()
    ),
    limits: z.array(z.lazy(() => UnderlyingPolicyLimitInputSchema())),
    policyNumber: z.string(),
    quoteId: z.string(),
    type: UnderlyingPolicyTypeSchema,
    underlyingPolicyCarrierId: z.string().nullish(),
  });
}

export function DataFillMapInputSchema(): z.ZodObject<Properties<DataFillMapInput>> {
  return z.object<Properties<DataFillMapInput>>({
    path: z.string().nullish(),
    tag: z.string(),
  });
}

export const DeclinedReasonSchema = z.nativeEnum(DeclinedReason);

export const DeductibleTypeOptionsEnumSchema = z.nativeEnum(DeductibleTypeOptionsEnum);

export function DefaultAutoDataInputSchema(): z.ZodObject<Properties<DefaultAutoDataInput>> {
  return z.object<Properties<DefaultAutoDataInput>>({
    make: z.string(),
    model: z.string(),
    modelYear: z.string(),
  });
}

export function DeleteFormPdfInputSchema(): z.ZodObject<Properties<DeleteFormPdfInput>> {
  return z.object<Properties<DeleteFormPdfInput>>({
    formGroupId: z.string(),
    formPdfId: z.string(),
  });
}

export function DeleteUnderlyingPolicyInputSchema(): z.ZodObject<
  Properties<DeleteUnderlyingPolicyInput>
> {
  return z.object<Properties<DeleteUnderlyingPolicyInput>>({
    id: z.string(),
  });
}

export function DuplicateFormPdfInputSchema(): z.ZodObject<Properties<DuplicateFormPdfInput>> {
  return z.object<Properties<DuplicateFormPdfInput>>({
    formGroupId: z.string(),
    formGroupInsertionIndex: z.number(),
    id: z.string(),
  });
}

export const EmployeeBenefitLiabilityLimitValueEnumSchema = z.nativeEnum(
  EmployeeBenefitLiabilityLimitValueEnum
);

export const EmployeeBenefitLiabilityNumberOfEmployeesValueEnumSchema = z.nativeEnum(
  EmployeeBenefitLiabilityNumberOfEmployeesValueEnum
);

export function EventInputSchema(): z.ZodObject<Properties<EventInput>> {
  return z.object<Properties<EventInput>>({
    eventName: z.string(),
    payload: z.any(),
  });
}

export function ExcessAutoCoverageOptionsIdInputSchema(): z.ZodObject<
  Properties<ExcessAutoCoverageOptionsIdInput>
> {
  return z.object<Properties<ExcessAutoCoverageOptionsIdInput>>({
    exposureId: z.string(),
  });
}

export function ExcessAutoCoverageOptionsInputSchema(): z.ZodObject<
  Properties<ExcessAutoCoverageOptionsInput>
> {
  return z.object<Properties<ExcessAutoCoverageOptionsInput>>({
    biOptionPPT: z.string().nullish(),
    biOptionTTT: z.string().nullish(),
    biOptionTrailer: z.string().nullish(),
    state: z.string(),
    uimbiLimit: z.string().nullish(),
    uimbiOption: z.string().nullish(),
    umbiLimit: z.string().nullish(),
    umbiOption: z.string().nullish(),
  });
}

export function ExcessAutoVehicleV2IdInputSchema(): z.ZodObject<
  Properties<ExcessAutoVehicleV2IdInput>
> {
  return z.object<Properties<ExcessAutoVehicleV2IdInput>>({
    exposureId: z.string(),
  });
}

export function ExcessAutoVehicleV2InputSchema(): z.ZodObject<
  Properties<ExcessAutoVehicleV2Input>
> {
  return z.object<Properties<ExcessAutoVehicleV2Input>>({
    exposureId: z.string(),
    make: z.string().nullish(),
    model: z.string().nullish(),
    modelYear: z.string().nullish(),
    ocnStatedAmount: z.string().nullish(),
    preciseAmount: z.number().nullish(),
    radius: z.string().nullish(),
    secondaryClassDetailPrivateClassDetail: z.string().nullish(),
    secondaryClassPrivateClass: z.string().nullish(),
    sizeClass: z.string().nullish(),
    state: z.string().nullish(),
    statedAmount: z.string().nullish(),
    typeOfVehicle: z.string().nullish(),
    use: z.string().nullish(),
    vin: z.string().nullish(),
    zipCode: z.string().nullish(),
  });
}

export const ExcessIsoClassSchema = z.nativeEnum(ExcessIsoClass);

export function ExclusionsInputSchema(): z.ZodObject<Properties<ExclusionsInput>> {
  return z.object<Properties<ExclusionsInput>>({
    residential: z.boolean(),
    wildfire: z.boolean(),
  });
}

export function ExpectedPayrollRiskTierInputSchema(): z.ZodObject<
  Properties<ExpectedPayrollRiskTierInput>
> {
  return z.object<Properties<ExpectedPayrollRiskTierInput>>({
    exposureBase: z.string(),
    isoClass: ExcessIsoClassSchema,
    payroll: z.number(),
    state: StateSchema,
    territory: z.string(),
  });
}

export function ExposureIdInputSchema(): z.ZodObject<Properties<ExposureIdInput>> {
  return z.object<Properties<ExposureIdInput>>({
    exposureId: z.string(),
    isProject: z.boolean(),
  });
}

export const ExposureTypeSchema = z.nativeEnum(ExposureType);

export const FileFileTypeSchema = z.nativeEnum(FileFileType);

export const FileObjectTypeSchema = z.nativeEnum(FileObjectType);

export const FileSourceSchema = z.nativeEnum(FileSource);

export const FileTypeSchema = z.nativeEnum(FileType);

export const FileTypeObjectSchema = z.nativeEnum(FileTypeObject);

export function FormPdfsInputSchema(): z.ZodObject<Properties<FormPdfsInput>> {
  return z.object<Properties<FormPdfsInput>>({
    includeAttachedToGroup: z.boolean(),
  });
}

export const FormStatusSchema = z.nativeEnum(FormStatus);

export const FormVendorSchema = z.nativeEnum(FormVendor);

export function GeneralLiabilityBuildoutScheduleInputSchema(): z.ZodObject<
  Properties<GeneralLiabilityBuildoutScheduleInput>
> {
  return z.object<Properties<GeneralLiabilityBuildoutScheduleInput>>({
    exposureId: z.string(),
    percentage: z.number(),
    year: z.string(),
  });
}

export const GeneralLiabilityDefenseExpenseTreatmentsEnumSchema = z.nativeEnum(
  GeneralLiabilityDefenseExpenseTreatmentsEnum
);

export const HiredAutosCoverageEnumSchema = z.nativeEnum(HiredAutosCoverageEnum);

export const HiredOwnedEnumSchema = z.nativeEnum(HiredOwnedEnum);

export function IdInputSchema(): z.ZodObject<Properties<IdInput>> {
  return z.object<Properties<IdInput>>({
    id: z.string(),
  });
}

export const IndicatedLostReasonSchema = z.nativeEnum(IndicatedLostReason);

export const InsightComparisonSchema = z.nativeEnum(InsightComparison);

export const InsightsLinkResponseSchema = z.nativeEnum(InsightsLinkResponse);

export const IntegrationLocationsSchema = z.nativeEnum(IntegrationLocations);

export const IntegrationsSchema = z.nativeEnum(Integrations);

export const InvoiceStatusSchema = z.nativeEnum(InvoiceStatus);

export const JurisdictionRiskTypeSchema = z.nativeEnum(JurisdictionRiskType);

export function LargeLossRunInputSchema(): z.ZodObject<Properties<LargeLossRunInput>> {
  return z.object<Properties<LargeLossRunInput>>({
    accidentDate: z.preprocess(
      // @ts-ignore
      (value: Date) => dayjs(value).format('YYYY-MM-DD'),
      z.string()
    ),
    alaeOutstanding: z.number(),
    effectiveDate: z.preprocess(
      // @ts-ignore
      (value: Date) => dayjs(value).format('YYYY-MM-DD'),
      z.string()
    ),
    expirationDate: z.preprocess(
      // @ts-ignore
      (value: Date) => dayjs(value).format('YYYY-MM-DD'),
      z.string()
    ),
    indemnityOutstanding: z.number(),
    paidAlae: z.number(),
    paidIndemnity: z.number(),
  });
}

export function LargeLossRunPhysDamRowInputSchema(): z.ZodObject<
  Properties<LargeLossRunPhysDamRowInput>
> {
  return z.object<Properties<LargeLossRunPhysDamRowInput>>({
    accidentDate: z.preprocess(
      // @ts-ignore
      (value: Date) => dayjs(value).format('YYYY-MM-DD'),
      z.string()
    ),
    effectiveDate: z.preprocess(
      // @ts-ignore
      (value: Date) => dayjs(value).format('YYYY-MM-DD'),
      z.string()
    ),
    expirationDate: z.preprocess(
      // @ts-ignore
      (value: Date) => dayjs(value).format('YYYY-MM-DD'),
      z.string()
    ),
    id: z.string().nullish(),
    indemnityOutstanding: z.number(),
    paidIndemnity: z.number(),
  });
}

export function LargeLossRunRowInputSchema(): z.ZodObject<Properties<LargeLossRunRowInput>> {
  return z.object<Properties<LargeLossRunRowInput>>({
    accidentDate: z.preprocess(
      // @ts-ignore
      (value: Date) => dayjs(value).format('YYYY-MM-DD'),
      z.string()
    ),
    alaeOutstanding: z.number(),
    effectiveDate: z.preprocess(
      // @ts-ignore
      (value: Date) => dayjs(value).format('YYYY-MM-DD'),
      z.string()
    ),
    expirationDate: z.preprocess(
      // @ts-ignore
      (value: Date) => dayjs(value).format('YYYY-MM-DD'),
      z.string()
    ),
    id: z.string().nullish(),
    indemnityOutstanding: z.number(),
    paidAlae: z.number(),
    paidIndemnity: z.number(),
  });
}

export const LiablityDeductibleOptionsObjectValueEnumSchema = z.nativeEnum(
  LiablityDeductibleOptionsObjectValueEnum
);

export const LineOfBusinessSchema = z.nativeEnum(LineOfBusiness);

export const LossRateSelectionTypeEnumSchema = z.nativeEnum(LossRateSelectionTypeEnum);

export function LossRunInputSchema(): z.ZodObject<Properties<LossRunInput>> {
  return z.object<Properties<LossRunInput>>({
    alaeOutstanding: z.number(),
    effectiveDate: z.preprocess(
      // @ts-ignore
      (value: Date) => dayjs(value).format('YYYY-MM-DD'),
      z.string()
    ),
    evaluationDate: z.preprocess(
      // @ts-ignore
      (value: Date) => dayjs(value).format('YYYY-MM-DD'),
      z.string()
    ),
    expirationDate: z.preprocess(
      // @ts-ignore
      (value: Date) => dayjs(value).format('YYYY-MM-DD'),
      z.string()
    ),
    historicalExposure: z.number(),
    id: z.string().nullish(),
    indemnityOutstanding: z.number(),
    nonZeroClaimCount: z.number(),
    paidAlae: z.number(),
    paidIndemnity: z.number(),
    totalClaimCount: z.number(),
  });
}

export const LossRunParsingAsyncRunStatusSchema = z.nativeEnum(LossRunParsingAsyncRunStatus);

export const LossRunParsingAsyncSourceSchema = z.nativeEnum(LossRunParsingAsyncSource);

export function LossRunPhysDamInputSchema(): z.ZodObject<Properties<LossRunPhysDamInput>> {
  return z.object<Properties<LossRunPhysDamInput>>({
    effectiveDate: z.preprocess(
      // @ts-ignore
      (value: Date) => dayjs(value).format('YYYY-MM-DD'),
      z.string()
    ),
    evaluationDate: z.preprocess(
      // @ts-ignore
      (value: Date) => dayjs(value).format('YYYY-MM-DD'),
      z.string()
    ),
    expirationDate: z.preprocess(
      // @ts-ignore
      (value: Date) => dayjs(value).format('YYYY-MM-DD'),
      z.string()
    ),
    historicalExposure: z.number(),
    id: z.string().nullish(),
    indemnityOutstanding: z.number(),
    nonZeroClaimCount: z.number(),
    paidIndemnity: z.number(),
    totalClaimCount: z.number(),
  });
}

export const LossRunVariantSchema = z.nativeEnum(LossRunVariant);

export function MutationAddressAgentSyncCheckInputSchema(): z.ZodObject<
  Properties<MutationAddressAgentSyncCheckInput>
> {
  return z.object<Properties<MutationAddressAgentSyncCheckInput>>({
    agentSyncCheckId: z.string(),
    licenseCheckId: z.string().nullish(),
    policyId: z.string(),
  });
}

export function MutationClearPolicyInputSchema(): z.ZodObject<
  Properties<MutationClearPolicyInput>
> {
  return z.object<Properties<MutationClearPolicyInput>>({
    blocked: z.boolean(),
    policyId: z.string(),
  });
}

export function MutationCreateFileWithUnderlyingPoliciesInputSchema(): z.ZodObject<
  Properties<MutationCreateFileWithUnderlyingPoliciesInput>
> {
  return z.object<Properties<MutationCreateFileWithUnderlyingPoliciesInput>>({
    fileInput: z.lazy(() => CreateFileInputSchema()),
    underlyingPolicyIds: z.array(z.string()),
  });
}

export function MutationCreateGoogleSessionInputSchema(): z.ZodObject<
  Properties<MutationCreateGoogleSessionInput>
> {
  return z.object<Properties<MutationCreateGoogleSessionInput>>({
    accessToken: z.string(),
  });
}

export function MutationCreateGraySessionInputSchema(): z.ZodObject<
  Properties<MutationCreateGraySessionInput>
> {
  return z.object<Properties<MutationCreateGraySessionInput>>({
    email: z.string().email(),
    quoteId: z.string(),
    source: z.string(),
  });
}

export function MutationCreateInvoiceInputSchema(): z.ZodObject<
  Properties<MutationCreateInvoiceInput>
> {
  return z.object<Properties<MutationCreateInvoiceInput>>({
    quoteId: z.string(),
  });
}

export function MutationCreatePricingExcelInputSchema(): z.ZodObject<
  Properties<MutationCreatePricingExcelInput>
> {
  return z.object<Properties<MutationCreatePricingExcelInput>>({
    isFinalPricing: z.boolean(),
    overwriteUwFile: z.boolean(),
    quoteId: z.string(),
    saveDocToUwFile: z.boolean(),
  });
}

export function MutationCreateProfitAndLossReportInputSchema(): z.ZodObject<
  Properties<MutationCreateProfitAndLossReportInput>
> {
  return z.object<Properties<MutationCreateProfitAndLossReportInput>>({
    quarter: z.number(),
    year: z.number(),
  });
}

export function MutationCreateSessionInputSchema(): z.ZodObject<
  Properties<MutationCreateSessionInput>
> {
  return z.object<Properties<MutationCreateSessionInput>>({
    code: z.string(),
    email: z.string().email(),
  });
}

export function MutationCreateUnderlyingPoliciesInputSchema(): z.ZodObject<
  Properties<MutationCreateUnderlyingPoliciesInput>
> {
  return z.object<Properties<MutationCreateUnderlyingPoliciesInput>>({
    fileInput: z.lazy(() => CreateFileInputSchema().nullish()),
    underlyingPolicies: z.array(z.lazy(() => CreateUnderlyingPolicyInputSchema())),
  });
}

export function MutationCreateVerificationRequestInputSchema(): z.ZodObject<
  Properties<MutationCreateVerificationRequestInput>
> {
  return z.object<Properties<MutationCreateVerificationRequestInput>>({
    email: z.string().email(),
  });
}

export function MutationDeleteExposureNoteInputSchema(): z.ZodObject<
  Properties<MutationDeleteExposureNoteInput>
> {
  return z.object<Properties<MutationDeleteExposureNoteInput>>({
    id: z.string(),
  });
}

export function MutationDownloadPricingExcelInputSchema(): z.ZodObject<
  Properties<MutationDownloadPricingExcelInput>
> {
  return z.object<Properties<MutationDownloadPricingExcelInput>>({
    isFinalPricing: z.boolean(),
    quoteId: z.string(),
  });
}

export function MutationGenerateLicensePdfInputSchema(): z.ZodObject<
  Properties<MutationGenerateLicensePdfInput>
> {
  return z.object<Properties<MutationGenerateLicensePdfInput>>({
    policyId: z.string(),
  });
}

export function MutationLoginInputSchema(): z.ZodObject<Properties<MutationLoginInput>> {
  return z.object<Properties<MutationLoginInput>>({
    email: z.string().email(),
  });
}

export function MutationRenewNonIssuedPolicyInputSchema(): z.ZodObject<
  Properties<MutationRenewNonIssuedPolicyInput>
> {
  return z.object<Properties<MutationRenewNonIssuedPolicyInput>>({
    policyId: z.string(),
  });
}

export function MutationRunLicenseChecksInputSchema(): z.ZodObject<
  Properties<MutationRunLicenseChecksInput>
> {
  return z.object<Properties<MutationRunLicenseChecksInput>>({
    policyId: z.string(),
  });
}

export function MutationRunOfacClearanceInputSchema(): z.ZodObject<
  Properties<MutationRunOfacClearanceInput>
> {
  return z.object<Properties<MutationRunOfacClearanceInput>>({
    policyId: z.string(),
  });
}

export function MutationUpdateDefaultAutoDataInputSchema(): z.ZodObject<
  Properties<MutationUpdateDefaultAutoDataInput>
> {
  return z.object<Properties<MutationUpdateDefaultAutoDataInput>>({
    toUpdate: z.array(z.lazy(() => UpdateDefaultAutoDataInputSchema())),
  });
}

export function MutationUpdateInvoiceInputSchema(): z.ZodObject<
  Properties<MutationUpdateInvoiceInput>
> {
  return z.object<Properties<MutationUpdateInvoiceInput>>({
    id: z.string(),
    status: InvoiceStatusSchema,
  });
}

export function MutationUpdateSelectedContainmentFactorInputSchema(): z.ZodObject<
  Properties<MutationUpdateSelectedContainmentFactorInput>
> {
  return z.object<Properties<MutationUpdateSelectedContainmentFactorInput>>({
    quoteDetailId: z.string(),
    selectedContainmentFactor: z.number().nullish(),
  });
}

export function MutationUpdateUserInputSchema(): z.ZodObject<Properties<MutationUpdateUserInput>> {
  return z.object<Properties<MutationUpdateUserInput>>({
    email: z.string().email(),
    firstName: z.string().nullish(),
    imageUrl: z.string().nullish(),
    lastName: z.string().nullish(),
    npn: z.string().nullish(),
    phoneNumber: z.string().nullish(),
    salesforceRecordId: z.string().nullish(),
    slackUserId: z.string().nullish(),
    title: z.string().nullish(),
  });
}

export function MutationUpsertExposureNoteInputSchema(): z.ZodObject<
  Properties<MutationUpsertExposureNoteInput>
> {
  return z.object<Properties<MutationUpsertExposureNoteInput>>({
    content: z.string(),
    endDate: z.string().nullish(),
    exposureType: LineOfBusinessSchema,
    id: z.string().nullish(),
    plainText: z.string(),
    priority: PrioritySchema,
    products: z.array(PolicyProductSchema),
    startDate: z.string(),
    state: z.string(),
  });
}

export const Naics2017SixDigitCodesSchema = z.nativeEnum(Naics2017SixDigitCodes);

export const NumberOfTrainsEnumSchema = z.nativeEnum(NumberOfTrainsEnum);

export const OcpLimitEnumSchema = z.nativeEnum(OcpLimitEnum);

export const OcpTypeOfOperationsEnumSchema = z.nativeEnum(OcpTypeOfOperationsEnum);

export const OfacStatusSchema = z.nativeEnum(OfacStatus);

export const OrganizationMemberRoleSchema = z.nativeEnum(OrganizationMemberRole);

export const OrganizationTypeSchema = z.nativeEnum(OrganizationType);

export function PaginatedPolicyInputSchema(): z.ZodObject<Properties<PaginatedPolicyInput>> {
  return z.object<Properties<PaginatedPolicyInput>>({
    assigneeIdSet: z.array(z.string()).nullish(),
    brokerIdSet: z.array(z.string()).nullish(),
    brokerageIdSet: z.array(z.string()).nullish(),
    productSet: z.array(PolicyProductSchema).nullish(),
    quoteStatusSet: z.array(QuoteStatusSchema).nullish(),
    skip: z.number(),
    sortDate: SortOrderSchema,
    sortName: SortOrderSchema.nullish(),
    sortProduct: SortOrderSchema.nullish(),
    sortSalesforceSynced: z.array(SortSalesforceSchema).nullish(),
    take: z.number(),
  });
}

export const PaymentPlanSchema = z.nativeEnum(PaymentPlan);

export function PayrollInputSchema(): z.ZodObject<Properties<PayrollInput>> {
  return z.object<Properties<PayrollInput>>({
    exposureBase: z.string(),
    isoClass: z.string(),
    ncciClass: z.string().nullish(),
    payroll: z.number(),
    state: StateSchema,
    territory: z.string(),
  });
}

export const PdfTypeSchema = z.nativeEnum(PdfType);

export function PolicyHoldersV3InputSchema(): z.ZodObject<Properties<PolicyHoldersV3Input>> {
  return z.object<Properties<PolicyHoldersV3Input>>({
    archivedFilter: z.boolean().nullish(),
    createdByIdFilter: z.array(z.string()).nullish(),
    hasAutoDeskFilter: z.boolean().nullish(),
    hasOpenSpaceFilter: z.boolean().nullish(),
    hasProcoreFilter: z.boolean().nullish(),
    skip: z.number(),
    sortCreatedAt: SortOrderSchema.nullish(),
    sortName: SortOrderSchema.nullish(),
    sortSalesforceSynced: z.array(SortSalesforceSchema).nullish(),
    sortState: SortOrderSchema.nullish(),
    sortUpdatedAt: SortOrderSchema.nullish(),
    stateSetFilter: z.array(z.string()).nullish(),
    take: z.number(),
  });
}

export const PolicyProductSchema = z.nativeEnum(PolicyProduct);

export const PremiumClassSchema = z.nativeEnum(PremiumClass);

export const PricingLineItemTypeSchema = z.nativeEnum(PricingLineItemType);

export function PrimaryAutoCostOfHireInputSchema(): z.ZodObject<
  Properties<PrimaryAutoCostOfHireInput>
> {
  return z.object<Properties<PrimaryAutoCostOfHireInput>>({
    costOfHire: z.number(),
    state: StateSchema,
  });
}

export function PrimaryAutoCoverageOptionsInputSchema(): z.ZodObject<
  Properties<PrimaryAutoCoverageOptionsInput>
> {
  return z.object<Properties<PrimaryAutoCoverageOptionsInput>>({
    biOptionPPT: z.string().nullish(),
    biOptionTTT: z.string().nullish(),
    biOptionTrailer: z.string().nullish(),
    compOptionPPT: z.string().nullish(),
    compOptionTTT: z.string().nullish(),
    compOptionTrailer: z.string().nullish(),
    medPayOptionPPT: z.string().nullish(),
    medPayOptionTTT: z.string().nullish(),
    medPayOptionTrailer: z.string().nullish(),
    pipOptionPPT: z.string().nullish(),
    pipOptionTTT: z.string().nullish(),
    pipOptionTrailer: z.string().nullish(),
    ppiOptionMiOnly: z.string().nullish(),
    specCauseOfLossPPT: z.string().nullish(),
    specCauseOfLossTTT: z.string().nullish(),
    specCauseOfLossTrailer: z.string().nullish(),
    state: z.string(),
    uimbiLimit: z.string().nullish(),
    uimbiOption: z.string().nullish(),
    umbiLimit: z.string().nullish(),
    umbiOption: z.string().nullish(),
    umpdLimit: z.string().nullish(),
    umpdOption: z.string().nullish(),
  });
}

export function PrimaryAutoIndividualRiskRatingSurveyInputSchema(): z.ZodObject<
  Properties<PrimaryAutoIndividualRiskRatingSurveyInput>
> {
  return z.object<Properties<PrimaryAutoIndividualRiskRatingSurveyInput>>({
    basicLimitPremiumTotalLiability: z.boolean().nullish(),
    basicLimitPremiumTotalLiabilityRationale: z.string().nullish(),
    broaderCoverageNeeded: z.boolean().nullish(),
    broaderCoverageNeededRationale: z.string().nullish(),
    coverageLimits: z.boolean().nullish(),
    coverageLimitsRationale: z.string().nullish(),
    coverageToRestrictUncommonHazard: z.boolean().nullish(),
    coverageToRestrictUncommonHazardRationale: z.string().nullish(),
    facultativeReinsurance: z.boolean().nullish(),
    facultativeReinsuranceRationale: z.string().nullish(),
    increasedLimitsPremium: z.boolean().nullish(),
    increasedLimitsPremiumRationale: z.string().nullish(),
    manualRateInappropriate: z.boolean().nullish(),
    manualRateInappropriateRationale: z.string().nullish(),
    restrictedCoverage: z.boolean().nullish(),
    restrictedCoverageRationale: z.string().nullish(),
  });
}

export function PrimaryAutoVehicleInputV2Schema(): z.ZodObject<
  Properties<PrimaryAutoVehicleInputV2>
> {
  return z.object<Properties<PrimaryAutoVehicleInputV2>>({
    hiredOwned: z.string().nullish(),
    make: z.string().nullish(),
    model: z.string().nullish(),
    modelYear: z.string().nullish(),
    ocnStatedAmount: z.string().nullish(),
    preciseAmount: z.number().nullish(),
    radius: z.string().nullish(),
    secondaryClassDetailPrivateClassDetail: z.string().nullish(),
    secondaryClassPrivateClass: z.string().nullish(),
    sizeClass: z.string().nullish(),
    state: z.string().nullish(),
    statedAmount: z.string().nullish(),
    subjectToPhysicalDamage: z.boolean().nullish(),
    typeOfVehicle: z.string().nullish(),
    use: z.string().nullish(),
    vin: z.string().nullish(),
    zipCode: z.string().nullish(),
  });
}

export function PrimaryGeneralLiabilityIndividualRiskRatingSurveyInputSchema(): z.ZodObject<
  Properties<PrimaryGeneralLiabilityIndividualRiskRatingSurveyInput>
> {
  return z.object<Properties<PrimaryGeneralLiabilityIndividualRiskRatingSurveyInput>>({
    aggregateLimitsChanged: z.boolean().nullish(),
    aggregateLimitsChangedRationale: z.string().nullish(),
    basicLimitPremiumTotalLiability: z.boolean().nullish(),
    basicLimitPremiumTotalLiabilityRationale: z.string().nullish(),
    broaderCoverageNeeded: z.boolean().nullish(),
    broaderCoverageNeededRationale: z.string().nullish(),
    coverageLimits: z.boolean().nullish(),
    coverageLimitsRationale: z.string().nullish(),
    coverageToRestrictUncommonHazard: z.boolean().nullish(),
    coverageToRestrictUncommonHazardRationale: z.string().nullish(),
    facultativeReinsurance: z.boolean().nullish(),
    facultativeReinsuranceRationale: z.string().nullish(),
    increasedLimitsPremium: z.boolean().nullish(),
    increasedLimitsPremiumRationale: z.string().nullish(),
    manualRateInappropriate: z.boolean().nullish(),
    manualRateInappropriateRationale: z.string().nullish(),
    premiumPco: z.boolean().nullish(),
    premiumPcoRationale: z.string().nullish(),
  });
}

export function PrimaryUnderwritingAdjustmentsInputSchema(): z.ZodObject<
  Properties<PrimaryUnderwritingAdjustmentsInput>
> {
  return z.object<Properties<PrimaryUnderwritingAdjustmentsInput>>({
    adjustmentBasis: AdjustmentBasisSchema.nullish(),
    adjustmentNotes: z.string().nullish(),
    alaeAggregateTreatmentOption: AlaeAggregateTreatmentOptionSchema.nullish(),
    autoBrokerageCommissionPercentage: z.number().nullish(),
    autoLiabilityUnderwritingAdjustment: z.number().nullish(),
    autoPhysDamUnderwritingAdjustment: z.number().nullish(),
    autoProfitLoadPercentage: z.number().nullish(),
    autoShepherdFeePercentage: z.number().nullish(),
    autoTpaChargePerPU: z.number().nullish(),
    dunAndBradstreetScore: z.number().nullish(),
    generalLiabilityBrokerageCommissionPercentage: z.number().nullish(),
    generalLiabilityProfitLoadPercentage: z.number().nullish(),
    generalLiabilityShepherdFeePercentage: z.number().nullish(),
    generalLiabilityTpaChargePer15MilOfVolume: z.number().nullish(),
    generalLiabilityUnderwritingAdjustment: z.number().nullish(),
    initialCollateralRequirementOverride: z.number().nullish(),
    initialPaidLossDepositFundOverride: z.number().nullish(),
    letterOfCreditAmountOverride: z.number().nullish(),
    paymentPlan: PaymentPlanSchema.nullish(),
    productsCompletedOperationsExtensionNumbeOfYears: z.number().nullish(),
    projectGeneralLiabilityExcessOfWrapCharge: z.number().nullish(),
    quoteDetailId: z.string(),
    selectedContainmentFactor: z.number().nullish(),
    singlePaymentOfPaidLossAndOrAlae: z.number().nullish(),
    tpaChargesNonClaimsHandling: z.number().nullish(),
    tria: z.boolean().nullish(),
  });
}

export const PrioritySchema = z.nativeEnum(Priority);

export const ProgramTypeSchema = z.nativeEnum(ProgramType);

export const ProjectGeneralAggregateValueEnumSchema = z.nativeEnum(
  ProjectGeneralAggregateValueEnum
);

export function QueryAutoExposureInputSchema(): z.ZodObject<Properties<QueryAutoExposureInput>> {
  return z.object<Properties<QueryAutoExposureInput>>({
    autoExposureId: z.string(),
    product: PolicyProductSchema,
  });
}

export function QueryDefaultAutoDataInputSchema(): z.ZodObject<
  Properties<QueryDefaultAutoDataInput>
> {
  return z.object<Properties<QueryDefaultAutoDataInput>>({
    toFind: z.array(z.lazy(() => DefaultAutoDataInputSchema())),
  });
}

export function QueryDefaultAutoZipCodesByMostCommonPayrollTerritoryInputSchema(): z.ZodObject<
  Properties<QueryDefaultAutoZipCodesByMostCommonPayrollTerritoryInput>
> {
  return z.object<Properties<QueryDefaultAutoZipCodesByMostCommonPayrollTerritoryInput>>({
    product: PolicyProductSchema,
    quoteId: z.string(),
    states: z.array(z.string()),
  });
}

export function QueryExposureNotesForQuoteInputSchema(): z.ZodObject<
  Properties<QueryExposureNotesForQuoteInput>
> {
  return z.object<Properties<QueryExposureNotesForQuoteInput>>({
    quoteId: z.string(),
  });
}

export function QueryHasNpnsInputSchema(): z.ZodObject<Properties<QueryHasNpnsInput>> {
  return z.object<Properties<QueryHasNpnsInput>>({
    policyId: z.string(),
  });
}

export function QueryHqZipCodeInputSchema(): z.ZodObject<Properties<QueryHqZipCodeInput>> {
  return z.object<Properties<QueryHqZipCodeInput>>({
    product: PolicyProductSchema,
    quoteId: z.string(),
  });
}

export function QueryLicenseChecksInputSchema(): z.ZodObject<Properties<QueryLicenseChecksInput>> {
  return z.object<Properties<QueryLicenseChecksInput>>({
    policyId: z.string(),
  });
}

export function QueryOrganizationLocationsInputSchema(): z.ZodObject<
  Properties<QueryOrganizationLocationsInput>
> {
  return z.object<Properties<QueryOrganizationLocationsInput>>({
    archived: z.boolean().nullish(),
    organizationId: z.string().nullish(),
  });
}

export function QueryPolicyClearanceResultsInputSchema(): z.ZodObject<
  Properties<QueryPolicyClearanceResultsInput>
> {
  return z.object<Properties<QueryPolicyClearanceResultsInput>>({
    policyId: z.string(),
  });
}

export function QueryUnderlyingPolicyFilesInputSchema(): z.ZodObject<
  Properties<QueryUnderlyingPolicyFilesInput>
> {
  return z.object<Properties<QueryUnderlyingPolicyFilesInput>>({
    policyId: z.string(),
  });
}

export const QuoteActivityTypeSchema = z.nativeEnum(QuoteActivityType);

export function QuoteDetailIdInputSchema(): z.ZodObject<Properties<QuoteDetailIdInput>> {
  return z.object<Properties<QuoteDetailIdInput>>({
    quoteDetailId: z.string(),
  });
}

export function QuoteIdInputSchema(): z.ZodObject<Properties<QuoteIdInput>> {
  return z.object<Properties<QuoteIdInput>>({
    quoteId: z.string(),
  });
}

export const QuotePdfStatusSchema = z.nativeEnum(QuotePdfStatus);

export const QuoteStatusSchema = z.nativeEnum(QuoteStatus);

export const QuotedLostReasonSchema = z.nativeEnum(QuotedLostReason);

export const RailroadLimitEnumSchema = z.nativeEnum(RailroadLimitEnum);

export const RailroadTypeOfOperationsEnumSchema = z.nativeEnum(RailroadTypeOfOperationsEnum);

export const ReasonForTechDataAbsenceSchema = z.nativeEnum(ReasonForTechDataAbsence);

export const RentalReimbursementDaysObjectValueEnumSchema = z.nativeEnum(
  RentalReimbursementDaysObjectValueEnum
);

export const RentalReimbursementObjectValueEnumSchema = z.nativeEnum(
  RentalReimbursementObjectValueEnum
);

export const RevenueTypeSchema = z.nativeEnum(RevenueType);

export function RiskTierAutoModelInputSchema(): z.ZodObject<Properties<RiskTierAutoModelInput>> {
  return z.object<Properties<RiskTierAutoModelInput>>({
    vehicleFleet: z.array(z.lazy(() => VehicleFleetInputSchema())),
  });
}

export function SafeOrganizationIntegrationPayloadSchema(): z.ZodObject<
  Properties<SafeOrganizationIntegrationPayload>
> {
  return z.object<Properties<SafeOrganizationIntegrationPayload>>({
    id: z.string(),
    provider: z.string(),
    providerOrganizationId: z.string(),
    providerOrganizationName: z.string(),
    providerUserId: z.string(),
  });
}

export const ScheduleRatingTypesSchema = z.nativeEnum(ScheduleRatingTypes);

export function SeedVehiclesAndCoveragesInputSchema(): z.ZodObject<
  Properties<SeedVehiclesAndCoveragesInput>
> {
  return z.object<Properties<SeedVehiclesAndCoveragesInput>>({
    coverages: z.array(z.lazy(() => PrimaryAutoCoverageOptionsInputSchema())),
    exposureId: z.string(),
    isProject: z.boolean(),
    vehicles: z.array(z.lazy(() => PrimaryAutoVehicleInputV2Schema())),
  });
}

export function SelectedFormsInputSchema(): z.ZodObject<Properties<SelectedFormsInput>> {
  return z.object<Properties<SelectedFormsInput>>({
    quoteId: z.string(),
  });
}

export const SelfAuditStatusSchema = z.nativeEnum(SelfAuditStatus);

export const SelfAuditStatusOptionsSchema = z.nativeEnum(SelfAuditStatusOptions);

export const ShepherdLineSchema = z.nativeEnum(ShepherdLine);

export function ShepsyInputSchema(): z.ZodObject<Properties<ShepsyInput>> {
  return z.object<Properties<ShepsyInput>>({
    prompt: z.string(),
  });
}

export const SortOrderSchema = z.nativeEnum(SortOrder);

export const SortSalesforceSchema = z.nativeEnum(SortSalesforce);

export const StateSchema = z.nativeEnum(State);

export function StateExceptionRulesEditInputSchema(): z.ZodObject<
  Properties<StateExceptionRulesEditInput>
> {
  return z.object<Properties<StateExceptionRulesEditInput>>({
    id: z.string(),
    state: z.string(),
    status: FormStatusSchema,
  });
}

export function StateExceptionRulesInputSchema(): z.ZodObject<
  Properties<StateExceptionRulesInput>
> {
  return z.object<Properties<StateExceptionRulesInput>>({
    state: z.string(),
    status: FormStatusSchema,
  });
}

export const SubcontractedCostVariantSchema = z.nativeEnum(SubcontractedCostVariant);

export function SubcontractedInputSchema(): z.ZodObject<Properties<SubcontractedInput>> {
  return z.object<Properties<SubcontractedInput>>({
    isoClass: z.string(),
    ncciClass: z.string().nullish(),
    payroll: z.number(),
    state: StateSchema,
    territory: z.string(),
  });
}

export const SubcontractedIsoClassSchema = z.nativeEnum(SubcontractedIsoClass);

export function SubjectivityInputObjectSchema(): z.ZodObject<Properties<SubjectivityInputObject>> {
  return z.object<Properties<SubjectivityInputObject>>({
    completed: z.boolean().nullish(),
    text: z.string(),
    type: SubjectivityTypeSchema,
  });
}

export const SubjectivityTypeSchema = z.nativeEnum(SubjectivityType);

export const TierEnumSchema = z.nativeEnum(TierEnum);

export const TimePeriodEnumSchema = z.nativeEnum(TimePeriodEnum);

export const TowAndLaborCostObjectValueEnumSchema = z.nativeEnum(TowAndLaborCostObjectValueEnum);

export const TriaOptionSchema = z.nativeEnum(TriaOption);

export const TypeOfVehicleEnumSchema = z.nativeEnum(TypeOfVehicleEnum);

export function UnderlyingPolicyLimitInputSchema(): z.ZodObject<
  Properties<UnderlyingPolicyLimitInput>
> {
  return z.object<Properties<UnderlyingPolicyLimitInput>>({
    amount: z.number(),
    type: UnderlyingPolicyLimitTypeSchema,
  });
}

export const UnderlyingPolicyLimitTypeSchema = z.nativeEnum(UnderlyingPolicyLimitType);

export const UnderlyingPolicyTypeSchema = z.nativeEnum(UnderlyingPolicyType);

export function UnmannedAircraftInputSchema(): z.ZodObject<Properties<UnmannedAircraftInput>> {
  return z.object<Properties<UnmannedAircraftInput>>({
    maximumTakeOffWeight: z.string(),
    ownershipAndOperation: z.string(),
    primaryPlace: z.string(),
    state: z.string(),
    usage: z.string(),
  });
}

export const UnmannedAircraftMaximumTakeOffWeightValueEnumSchema = z.nativeEnum(
  UnmannedAircraftMaximumTakeOffWeightValueEnum
);

export const UnmannedAircraftOwnershipAndOperationValueEnumSchema = z.nativeEnum(
  UnmannedAircraftOwnershipAndOperationValueEnum
);

export const UnmannedAircraftPlaceOfOperationModifiersValueEnumSchema = z.nativeEnum(
  UnmannedAircraftPlaceOfOperationModifiersValueEnum
);

export const UnmannedAircraftUsageModifiersEnumSchema = z.nativeEnum(
  UnmannedAircraftUsageModifiersEnum
);

export const UnmannedAircraftVariantSchema = z.nativeEnum(UnmannedAircraftVariant);

export function UpdateDefaultAutoDataInputSchema(): z.ZodObject<
  Properties<UpdateDefaultAutoDataInput>
> {
  return z.object<Properties<UpdateDefaultAutoDataInput>>({
    make: z.string(),
    model: z.string(),
    modelYear: z.string(),
    sizeClass: z.string(),
    statedAmount: z.string().nullish(),
  });
}

export function UpdateExcessAutoCoverageOptionInputSchema(): z.ZodObject<
  Properties<UpdateExcessAutoCoverageOptionInput>
> {
  return z.object<Properties<UpdateExcessAutoCoverageOptionInput>>({
    coverageOption: z.lazy(() => ExcessAutoCoverageOptionsInputSchema()),
    id: z.string(),
  });
}

export function UpdateExcessAutoCoverageOptionsInputSchema(): z.ZodObject<
  Properties<UpdateExcessAutoCoverageOptionsInput>
> {
  return z.object<Properties<UpdateExcessAutoCoverageOptionsInput>>({
    coverageOptions: z.array(z.lazy(() => ExcessAutoCoverageOptionsInputSchema())),
    exposureId: z.string(),
  });
}

export function UpdateExcessAutoExposureInputSchema(): z.ZodObject<
  Properties<UpdateExcessAutoExposureInput>
> {
  return z.object<Properties<UpdateExcessAutoExposureInput>>({
    attachmentPoint: z.number().nullish(),
    autoLiabilityScheduleRatings: z.array(CreateScheduleRatingsSchema()).nullish(),
    finalSummarySelectedLossRate: definedNonNullAnySchema.nullish(),
    finalSummarySelectedLossRateType: LossRateSelectionTypeEnumSchema.nullish(),
    fleetExposure: z.array(z.lazy(() => AutoVehicleInputSchema())).nullish(),
    id: z.string(),
    individualNamedInsured: z.boolean().nullish(),
    isCredibilityCapEnabled: z.boolean().nullish(),
    isExperienceRatingEnabled: z.boolean().nullish(),
    isUmUimIncluded: z.boolean().nullish(),
    largeLossRuns: z.array(z.lazy(() => AutoLargeLossRunInputSchema())).nullish(),
    lossRuns: z.array(z.lazy(() => AutoLossRunInputSchema())).nullish(),
    naicsClassCode: z.string().nullish(),
    selectedFrequency: definedNonNullAnySchema.nullish(),
    selectedFrequencyType: LossRateSelectionTypeEnumSchema.nullish(),
    selectedLossRate: definedNonNullAnySchema.nullish(),
    selectedLossRateType: LossRateSelectionTypeEnumSchema.nullish(),
    telematicsProvider: z.string().nullish(),
    underlyingPolicyLimit: z.number().nullish(),
    usesTelematicsProvider: z.boolean().nullish(),
  });
}

export function UpdateExcessAutoVehicleV2InputSchema(): z.ZodObject<
  Properties<UpdateExcessAutoVehicleV2Input>
> {
  return z.object<Properties<UpdateExcessAutoVehicleV2Input>>({
    exposureId: z.string(),
    fleet: z.array(z.lazy(() => ExcessAutoVehicleV2InputSchema())),
  });
}

export function UpdateExcessGeneralLiabilityExposureInputSchema(): z.ZodObject<
  Properties<UpdateExcessGeneralLiabilityExposureInput>
> {
  return z.object<Properties<UpdateExcessGeneralLiabilityExposureInput>>({
    attachmentPoint: z.number().nullish(),
    finalSummarySelectedLossRate: definedNonNullAnySchema.nullish(),
    finalSummarySelectedLossRateType: LossRateSelectionTypeEnumSchema.nullish(),
    id: z.string(),
    isCredibilityCapEnabled: z.boolean().nullish(),
    isExperienceRatingEnabled: z.boolean().nullish(),
    numberOfProjectsLocations: z.number().nullish(),
    overallAggregateCap: z.number().nullish(),
    product: PolicyProductSchema.nullish(),
    programVolume: z.number().nullish(),
    projectsGeneralAggregateOption: z.string().nullish(),
    residentialExclusion: z.boolean().nullish(),
    selectedFrequency: definedNonNullAnySchema.nullish(),
    selectedFrequencyType: LossRateSelectionTypeEnumSchema.nullish(),
    selectedLossRate: definedNonNullAnySchema.nullish(),
    selectedLossRateType: LossRateSelectionTypeEnumSchema.nullish(),
    underlyingPolicyLimit: z.number().nullish(),
    underlyingPolicyPremium: z.number().nullish(),
    wildfireExclusion: z.boolean().nullish(),
  });
}

export function UpdateFileInputSchema(): z.ZodObject<Properties<UpdateFileInput>> {
  return z.object<Properties<UpdateFileInput>>({
    id: z.string(),
    name: z.string(),
  });
}

export function UpdateFormGroupInputSchema(): z.ZodObject<Properties<UpdateFormGroupInput>> {
  return z.object<Properties<UpdateFormGroupInput>>({
    id: z.string(),
    name: z.string().nullish(),
    ordering: z.array(z.string()).nullish(),
  });
}

export function UpdateFormPdfInputSchema(): z.ZodObject<Properties<UpdateFormPdfInput>> {
  return z.object<Properties<UpdateFormPdfInput>>({
    active: z.boolean(),
    category: z.string().nullish(),
    defaultStatus: FormStatusSchema,
    file: z.unknown().nullish(),
    formNumber: z.string(),
    formType: z.string(),
    googleDocUrl: z.string().nullish(),
    hasEndorsementNumber: z.boolean(),
    id: z.string(),
    isPdf: z.boolean().nullish(),
    lineOfBusiness: LineOfBusinessSchema,
    notes: z.string(),
    stateExceptionRules: z.array(z.lazy(() => StateExceptionRulesEditInputSchema())).nullish(),
    stateExceptions: z.array(z.string()).nullish(),
    tria: TriaOptionSchema,
  });
}

export function UpdateFormPdfTagsInputSchema(): z.ZodObject<Properties<UpdateFormPdfTagsInput>> {
  return z.object<Properties<UpdateFormPdfTagsInput>>({
    dataFillMap: z.array(z.lazy(() => DataFillMapInputSchema())),
    id: z.string(),
  });
}

export function UpdateOrganizationInputSchema(): z.ZodObject<Properties<UpdateOrganizationInput>> {
  return z.object<Properties<UpdateOrganizationInput>>({
    archive: z.boolean().nullish(),
    city: z.string().nullish(),
    einNumber: z.string().nullish(),
    id: z.string(),
    logoUrl: z.string().nullish(),
    name: z.string(),
    npn: z.string().nullish(),
    parentOrganizationName: z.string().nullish(),
    postalCode: z.string().nullish(),
    salesforceRecordId: z.string().nullish(),
    state: StateSchema.nullish(),
    street: z.string().nullish(),
    street2: z.string().nullish(),
    type: OrganizationTypeSchema,
  });
}

export function UpdateOrganizationLocationInputSchema(): z.ZodObject<
  Properties<UpdateOrganizationLocationInput>
> {
  return z.object<Properties<UpdateOrganizationLocationInput>>({
    address1: z.string().nullish(),
    address2: z.string().nullish(),
    assigneeId: z.string().nullish(),
    city: z.string().nullish(),
    excessSurplusLinesPaidByPartner: z.boolean().nullish(),
    id: z.string(),
    name: z.string(),
    organizationId: z.string(),
    salesforceRecordId: z.string().nullish(),
    state: StateSchema.nullish(),
    zipcode: z.string().nullish(),
  });
}

export function UpdateOrganizationUserInputSchema(): z.ZodObject<
  Properties<UpdateOrganizationUserInput>
> {
  return z.object<Properties<UpdateOrganizationUserInput>>({
    currentOrganizationId: z.string(),
    email: z.string().email(),
    firstName: z.string(),
    id: z.string(),
    lastName: z.string(),
    npn: z.string().nullish(),
    organizationId: z.string(),
    organizationLocationId: z.string().nullish(),
    role: OrganizationMemberRoleSchema,
    salesforceRecordId: z.string().nullish(),
  });
}

export function UpdatePolicyChangeEndorsementInputSchema(): z.ZodObject<
  Properties<UpdatePolicyChangeEndorsementInput>
> {
  return z.object<Properties<UpdatePolicyChangeEndorsementInput>>({
    brokerCommissionPercentage: z.number(),
    effectiveDate: z.preprocess(
      // @ts-ignore
      (value: Date) => dayjs(value).format('YYYY-MM-DD'),
      z.string()
    ),
    employersLiabilityFeePercentage: z.number(),
    frontingFeePercentage: z.number(),
    id: z.string(),
    issuedDate: z.preprocess(
      // @ts-ignore
      (value: Date) => dayjs(value).format('YYYY-MM-DD'),
      z.string()
    ),
    name: z.string(),
    premium: z.number(),
    profitLoadFeePercentage: z.number(),
    quoteId: z.string(),
    shepherdMgaFeePercentage: z.number(),
    totalExpenseLoadPercentage: z.number(),
    tpaFeePercentage: z.number(),
    triaFeePercentage: z.number(),
  });
}

export function UpdatePolicyInputSchema(): z.ZodObject<Properties<UpdatePolicyInput>> {
  return z.object<Properties<UpdatePolicyInput>>({
    assigneeId: z.string().nullish(),
    brokerOrganizationMemberId: z.string().nullish(),
    businessType: BusinessTypeSchema.nullish(),
    contractorId: z.string().nullish(),
    effectiveDate: z
      .preprocess(
        // @ts-ignore
        (value: Date) => dayjs(value).format('YYYY-MM-DD'),
        z.string()
      )
      .nullish(),
    expirationDate: z
      .preprocess(
        // @ts-ignore
        (value: Date) => dayjs(value).format('YYYY-MM-DD'),
        z.string()
      )
      .nullish(),
    expiringEffectiveDate: z
      .preprocess(
        // @ts-ignore
        (value: Date) => dayjs(value).format('YYYY-MM-DD'),
        z.string()
      )
      .nullish(),
    id: z.string(),
    licensedUnderwriterId: z.string().nullish(),
    name: z.string(),
    namedInsureds: z.array(z.string()).nullish(),
    policyNumber: z.string().nullish(),
    programType: ProgramTypeSchema.nullish(),
    submissionAt: z.string().nullish(),
  });
}

export function UpdatePolicyholderInputSchema(): z.ZodObject<Properties<UpdatePolicyholderInput>> {
  return z.object<Properties<UpdatePolicyholderInput>>({
    address1: z.string().nullish(),
    address2: z.string().nullish(),
    city: z.string().nullish(),
    country: z.string().nullish(),
    fein: z.string().nullish(),
    hasAutodesk: z.boolean().nullish(),
    id: z.string(),
    insuredContactEmail: z.string().email().nullish(),
    insuredContactName: z.string().nullish(),
    name: z.string(),
    state: StateSchema,
    website: z.string().nullish(),
    zipcode: z.string().nullish(),
  });
}

export function UpdatePrimaryAutoCoverageOptionInputSchema(): z.ZodObject<
  Properties<UpdatePrimaryAutoCoverageOptionInput>
> {
  return z.object<Properties<UpdatePrimaryAutoCoverageOptionInput>>({
    coverageOption: z.lazy(() => PrimaryAutoCoverageOptionsInputSchema()),
    id: z.string(),
    isProject: z.boolean(),
  });
}

export function UpdatePrimaryAutoExposureInputSchema(): z.ZodObject<
  Properties<UpdatePrimaryAutoExposureInput>
> {
  return z.object<Properties<UpdatePrimaryAutoExposureInput>>({
    attachmentPoint: z.number().nullish(),
    autoHackingCoverageOption: AutoHackingCoverageOptionEnumSchema.nullish(),
    autoLoanLeaseGapCoverage: z.boolean().nullish(),
    collisionDeductible: CollisionDeductibleOptionsObjectValueEnumSchema.nullish(),
    collisionDeductibleCostOfHireBasis:
      CollisionDeductibleCostOfHireBasisObjectValueEnumSchema.nullish(),
    combinedSingleLimit: CombinedSingleLimitOptionsObjectValueEnumSchema.nullish(),
    comprehensiveDeductible: ComprehensiveDeductibleOptionsObjectValueEnumSchema.nullish(),
    comprehensiveDeductibleCostOfHireBasis:
      ComprehensiveDeductibleCostOfHireBasisObjectValueEnumSchema.nullish(),
    costOfHires: z.array(z.lazy(() => PrimaryAutoCostOfHireInputSchema())).nullish(),
    defenseExpenseTreatment: AutoLiabilityDefenseExpenseTreatmentsEnumSchema.nullish(),
    driveOtherCar: z.boolean().nullish(),
    extendCoverageToIndividualEmployees: z.boolean().nullish(),
    extendToCoverOwner: z.boolean().nullish(),
    finalSummarySelectedLossRate: definedNonNullAnySchema.nullish(),
    finalSummarySelectedLossRateType: LossRateSelectionTypeEnumSchema.nullish(),
    hiredAutosCoverage: HiredAutosCoverageEnumSchema.nullish(),
    id: z.string(),
    individualNamedInsured: z.boolean().nullish(),
    individualRiskRatingSurvey: z.lazy(() =>
      PrimaryAutoIndividualRiskRatingSurveyInputSchema().nullish()
    ),
    isCredibilityCapEnabled: z.boolean().nullish(),
    isExperienceRatedPathEnabledAutoLiability: z.boolean().nullish(),
    isExperienceRatedPathEnabledAutoPhysDam: z.boolean().nullish(),
    liabilityDeductible: LiablityDeductibleOptionsObjectValueEnumSchema.nullish(),
    naicsClassCode: z.string().nullish(),
    nonOwnershipLiability: z.boolean().nullish(),
    numberOfEmployees: z.string().nullish(),
    numberOfIndividualNamedInsureds: z.number().nullish(),
    numberOfPartners: z.number().nullish(),
    partnershipOrLlc: z.boolean().nullish(),
    pollutionLiability: z.boolean().nullish(),
    pptCollisionDeductible: z.boolean().nullish(),
    pptComprehensiveDeductible: z.boolean().nullish(),
    rentalReimbursementCoverage: z.boolean().nullish(),
    rentalReimbursementDays: RentalReimbursementDaysObjectValueEnumSchema.nullish(),
    rentalReimbursementLimit: RentalReimbursementObjectValueEnumSchema.nullish(),
    selectedFrequency: definedNonNullAnySchema.nullish(),
    selectedFrequencyType: LossRateSelectionTypeEnumSchema.nullish(),
    selectedLossRate: definedNonNullAnySchema.nullish(),
    selectedLossRateType: LossRateSelectionTypeEnumSchema.nullish(),
    subjectToLossAggregate: z.boolean().nullish(),
    towAndLaborCost: TowAndLaborCostObjectValueEnumSchema.nullish(),
    towAndLaborCostCoverage: z.boolean().nullish(),
    tttCollisionDeductible: z.boolean().nullish(),
    tttComprehensiveDeductible: z.boolean().nullish(),
    underlyingLimitExcessRating: z.number().nullish(),
  });
}

export function UpdatePrimaryAutoVehiclesInputSchema(): z.ZodObject<
  Properties<UpdatePrimaryAutoVehiclesInput>
> {
  return z.object<Properties<UpdatePrimaryAutoVehiclesInput>>({
    exposureId: z.string(),
    fleet: z.array(z.lazy(() => PrimaryAutoVehicleInputV2Schema())),
    isProject: z.boolean(),
  });
}

export function UpdatePrimaryGeneralLiabilityExposureInputSchema(): z.ZodObject<
  Properties<UpdatePrimaryGeneralLiabilityExposureInput>
> {
  return z.object<Properties<UpdatePrimaryGeneralLiabilityExposureInput>>({
    damageToPremisesRentedToYou: z.number().nullish(),
    deductibleOption: z.string().nullish(),
    deductiblePco: z.number().nullish(),
    deductiblePremOps: z.number().nullish(),
    defenseExpenseTreatment: GeneralLiabilityDefenseExpenseTreatmentsEnumSchema.nullish(),
    defenseInsideLimits: z.boolean().nullish(),
    eachOccurrenceLimit: z.number().nullish(),
    employeeBenefitLiabilityCoverage: z.boolean().nullish(),
    employeeBenefitLiabilityLimit: z.string().nullish(),
    employeeBenefitLiabilityNumberOfEmployees:
      EmployeeBenefitLiabilityNumberOfEmployeesValueEnumSchema.nullish(),
    employeeBenefitLiabilityOptionAggregateLimit: z.number().nullish(),
    employeeBenefitLiabilityOptionPerOccurenceLimit: z.number().nullish(),
    employeeBenefitLiabilityRetroDate: z
      .preprocess(
        // @ts-ignore
        (value: Date) => dayjs(value).format('YYYY-MM-DD'),
        z.string()
      )
      .nullish(),
    employerLiabilityBodilyInjuryByAccidentEachAccident: z.number().nullish(),
    employerLiabilityBodilyInjuryByDiseaseAggregate: z.number().nullish(),
    employerLiabilityBodilyInjuryByDiseaseEachEmployee: z.number().nullish(),
    employerLiabilityCoverage: z.boolean().nullish(),
    finalSummarySelectedLossRate: definedNonNullAnySchema.nullish(),
    finalSummarySelectedLossRateType: LossRateSelectionTypeEnumSchema.nullish(),
    generalAggregateLimit: z.number().nullish(),
    generalLiabilityTpaChargePer15MilOfVolume: z.number().nullish(),
    id: z.string(),
    individualRiskRatingSurvey: z.lazy(() =>
      PrimaryGeneralLiabilityIndividualRiskRatingSurveyInputSchema().nullish()
    ),
    isCredibilityCapEnabled: z.boolean().nullish(),
    isExperienceRatedPathEnabled: z.boolean().nullish(),
    medicalExpenseLimit: z.number().nullish(),
    numberOfProjectsLocations: z.number().nullish(),
    overallAggregateCap: z.number().nullish(),
    pcoAggregateLimit: z.number().nullish(),
    personalAdvertisingInjuryLimit: z.number().nullish(),
    product: PolicyProductSchema.nullish(),
    programVolume: z.number().nullish(),
    projectsGeneralAggregateOption: z.string().nullish(),
    residentialExclusion: z.boolean().nullish(),
    selectedFrequency: definedNonNullAnySchema.nullish(),
    selectedFrequencyType: LossRateSelectionTypeEnumSchema.nullish(),
    selectedLossRate: definedNonNullAnySchema.nullish(),
    selectedLossRateType: LossRateSelectionTypeEnumSchema.nullish(),
    subjectToLossAggregate: z.boolean().nullish(),
    wildfireExclusion: z.boolean().nullish(),
  });
}

export function UpdatePrimaryOcpExposureSchema(): z.ZodObject<
  Properties<UpdatePrimaryOcpExposure>
> {
  return z.object<Properties<UpdatePrimaryOcpExposure>>({
    id: z.string(),
    ocpLimit: z.string().nullish(),
    ocpTotalCost: z.number().nullish(),
    policyState: z.string().nullish(),
    typeOfOperations: z.string().nullish(),
  });
}

export function UpdatePrimaryRailroadCoverageInputSchema(): z.ZodObject<
  Properties<UpdatePrimaryRailroadCoverageInput>
> {
  return z.object<Properties<UpdatePrimaryRailroadCoverageInput>>({
    id: z.string(),
    numberOfPassengerAndFreightTrainsPerDay: z.string().nullish(),
    policyState: z.string().nullish(),
    railroadLimit: z.string().nullish(),
    railroadOpsExtendedCoverage: z.boolean().nullish(),
    railroadOpsTotalCost: z.number().nullish(),
    typeOfOperationsForRailroadOps: z.string().nullish(),
  });
}

export function UpdateProcoreUsageInputSchema(): z.ZodObject<Properties<UpdateProcoreUsageInput>> {
  return z.object<Properties<UpdateProcoreUsageInput>>({
    adoptionDate: z
      .preprocess(
        // @ts-ignore
        (value: Date) => dayjs(value).format('YYYY-MM-DD'),
        z.string()
      )
      .nullish(),
    annualRevenue: z.number().nullish(),
    coreToolsEngagement: z.number().nullish(),
    csEngagement: z.number().nullish(),
    id: z.string(),
    overallUsage: z.number().nullish(),
    policyholderId: z.string(),
    projectFinancials: z.number().nullish(),
    projectManagement: z.number().nullish(),
    qualitySafety: z.number().nullish(),
    totalActiveUsers: z.number().nullish(),
  });
}

export function UpdateProjectExcessGeneralLiabilityExposureInputSchema(): z.ZodObject<
  Properties<UpdateProjectExcessGeneralLiabilityExposureInput>
> {
  return z.object<Properties<UpdateProjectExcessGeneralLiabilityExposureInput>>({
    additionalStates: z.array(StateSchema).nullish(),
    address1: z.string().nullish(),
    address2: z.string().nullish(),
    attachmentPoint: z.number().nullish(),
    buildoutSchedules: z
      .array(z.lazy(() => GeneralLiabilityBuildoutScheduleInputSchema()))
      .nullish(),
    cdSensitiveProjectTypeOne: z.boolean().nullish(),
    cdSensitiveProjectTypeTwo: z.boolean().nullish(),
    city: z.string().nullish(),
    country: z.string().nullish(),
    id: z.string(),
    isExperienceRatingEnabled: z.boolean().nullish(),
    numberOfProjectsLocations: z.number().nullish(),
    overallAggregateCap: z.number().nullish(),
    programVolume: z.number().nullish(),
    projectName: z.string().nullish(),
    projectsGeneralAggregateOption: z.string().nullish(),
    residentialExclusion: z.boolean().nullish(),
    scheduleRatings: z.array(CreateScheduleRatingsSchema()).nullish(),
    state: StateSchema.nullish(),
    underlyingPolicyLimit: z.number().nullish(),
    underlyingPolicyPremium: z.number().nullish(),
    wildfireExclusion: z.boolean().nullish(),
    zipcode: z.string().nullish(),
  });
}

export function UpdateProjectPrimaryAutoExposureInputSchema(): z.ZodObject<
  Properties<UpdateProjectPrimaryAutoExposureInput>
> {
  return z.object<Properties<UpdateProjectPrimaryAutoExposureInput>>({
    autoHackingCoverageOption: AutoHackingCoverageOptionEnumSchema.nullish(),
    autoLiabilityScheduleRatings: z.array(CreateScheduleRatingsSchema()).nullish(),
    autoLoanLeaseGapCoverage: z.boolean().nullish(),
    autoPhysicalDamageScheduleRatings: z.array(CreateScheduleRatingsSchema()).nullish(),
    collisionDeductible: CollisionDeductibleOptionsObjectValueEnumSchema.nullish(),
    collisionDeductibleCostOfHireBasis:
      CollisionDeductibleCostOfHireBasisObjectValueEnumSchema.nullish(),
    combinedSingleLimit: CombinedSingleLimitOptionsObjectValueEnumSchema.nullish(),
    comprehensiveDeductible: ComprehensiveDeductibleOptionsObjectValueEnumSchema.nullish(),
    comprehensiveDeductibleCostOfHireBasis:
      ComprehensiveDeductibleCostOfHireBasisObjectValueEnumSchema.nullish(),
    costOfHires: z.array(z.lazy(() => PrimaryAutoCostOfHireInputSchema())).nullish(),
    defenseExpenseTreatment: AutoLiabilityDefenseExpenseTreatmentsEnumSchema.nullish(),
    driveOtherCar: z.boolean().nullish(),
    extendCoverageToIndividualEmployees: z.boolean().nullish(),
    extendToCoverOwner: z.boolean().nullish(),
    finalSummarySelectedLossRate: definedNonNullAnySchema.nullish(),
    finalSummarySelectedLossRateType: LossRateSelectionTypeEnumSchema.nullish(),
    hiredAutosCoverage: HiredAutosCoverageEnumSchema.nullish(),
    id: z.string(),
    individualNamedInsured: z.boolean().nullish(),
    individualRiskRatingSurvey: z.lazy(() =>
      PrimaryAutoIndividualRiskRatingSurveyInputSchema().nullish()
    ),
    isCredibilityCapEnabled: z.boolean().nullish(),
    isExperienceRatedPathEnabledAutoLiability: z.boolean().nullish(),
    isExperienceRatedPathEnabledAutoPhysDam: z.boolean().nullish(),
    liabilityDeductible: LiablityDeductibleOptionsObjectValueEnumSchema.nullish(),
    naicsClassCode: z.string().nullish(),
    nonOwnershipLiability: z.boolean().nullish(),
    numberOfEmployees: z.string().nullish(),
    numberOfIndividualNamedInsureds: z.number().nullish(),
    numberOfPartners: z.number().nullish(),
    partnershipOrLlc: z.boolean().nullish(),
    pollutionLiability: z.boolean().nullish(),
    pptCollisionDeductible: z.boolean().nullish(),
    pptComprehensiveDeductible: z.boolean().nullish(),
    rentalReimbursementCoverage: z.boolean().nullish(),
    rentalReimbursementDays: RentalReimbursementDaysObjectValueEnumSchema.nullish(),
    rentalReimbursementLimit: RentalReimbursementObjectValueEnumSchema.nullish(),
    selectedFrequency: definedNonNullAnySchema.nullish(),
    selectedFrequencyType: LossRateSelectionTypeEnumSchema.nullish(),
    selectedLossRate: definedNonNullAnySchema.nullish(),
    selectedLossRateType: LossRateSelectionTypeEnumSchema.nullish(),
    subjectToLossAggregate: z.boolean().nullish(),
    towAndLaborCost: TowAndLaborCostObjectValueEnumSchema.nullish(),
    towAndLaborCostCoverage: z.boolean().nullish(),
    tttCollisionDeductible: z.boolean().nullish(),
    tttComprehensiveDeductible: z.boolean().nullish(),
    underlyingLimitExcessRating: z.number().nullish(),
  });
}

export function UpdateProjectPrimaryGeneralLiabilityExposureSchema(): z.ZodObject<
  Properties<UpdateProjectPrimaryGeneralLiabilityExposure>
> {
  return z.object<Properties<UpdateProjectPrimaryGeneralLiabilityExposure>>({
    additionalStates: z.array(StateSchema).nullish(),
    address1: z.string().nullish(),
    address2: z.string().nullish(),
    buildoutSchedule: z
      .array(z.lazy(() => GeneralLiabilityBuildoutScheduleInputSchema()))
      .nullish(),
    cdSensitiveProjectTypeOne: z.boolean().nullish(),
    cdSensitiveProjectTypeTwo: z.boolean().nullish(),
    city: z.string().nullish(),
    country: z.string().nullish(),
    damageToPremisesRentedToYou: z.number().nullish(),
    deductibleOption: z.string().nullish(),
    deductiblePco: z.number().nullish(),
    deductiblePremOps: z.number().nullish(),
    defenseExpenseTreatment: GeneralLiabilityDefenseExpenseTreatmentsEnumSchema.nullish(),
    defenseInsideLimits: z.boolean().nullish(),
    eachOccurrenceLimit: z.number().nullish(),
    employeeBenefitLiabilityCoverage: z.boolean().nullish(),
    employeeBenefitLiabilityLimit: z.string().nullish(),
    employeeBenefitLiabilityNumberOfEmployees:
      EmployeeBenefitLiabilityNumberOfEmployeesValueEnumSchema.nullish(),
    employeeBenefitLiabilityOptionAggregateLimit: z.number().nullish(),
    employeeBenefitLiabilityOptionPerOccurenceLimit: z.number().nullish(),
    employeeBenefitLiabilityRetroDate: z
      .preprocess(
        // @ts-ignore
        (value: Date) => dayjs(value).format('YYYY-MM-DD'),
        z.string()
      )
      .nullish(),
    employerLiabilityBodilyInjuryByAccidentEachAccident: z.number().nullish(),
    employerLiabilityBodilyInjuryByDiseaseAggregate: z.number().nullish(),
    employerLiabilityBodilyInjuryByDiseaseEachEmployee: z.number().nullish(),
    employerLiabilityCoverage: z.boolean().nullish(),
    finalSummarySelectedLossRate: definedNonNullAnySchema.nullish(),
    finalSummarySelectedLossRateType: LossRateSelectionTypeEnumSchema.nullish(),
    generalAggregateLimit: z.number().nullish(),
    id: z.string(),
    individualRiskRatingSurvey: z.lazy(() =>
      PrimaryGeneralLiabilityIndividualRiskRatingSurveyInputSchema().nullish()
    ),
    isCredibilityCapEnabled: z.boolean().nullish(),
    isExperienceRatedPathEnabled: z.boolean().nullish(),
    medicalExpenseLimit: z.number().nullish(),
    numberOfProjectsLocations: z.number().nullish(),
    overallAggregateCap: z.number().nullish(),
    pcoAggregateLimit: z.number().nullish(),
    personalAdvertisingInjuryLimit: z.number().nullish(),
    product: PolicyProductSchema.nullish(),
    programVolume: z.number().nullish(),
    projectDescription: z.string().nullish(),
    projectName: z.string().nullish(),
    projectsGeneralAggregateOption: z.string().nullish(),
    residentialExclusion: z.boolean().nullish(),
    scheduleRatings: z.lazy(() => CreateScheduleRatingsInputSchema().nullish()),
    selectedFrequency: definedNonNullAnySchema.nullish(),
    selectedFrequencyType: LossRateSelectionTypeEnumSchema.nullish(),
    selectedLossRate: definedNonNullAnySchema.nullish(),
    selectedLossRateType: LossRateSelectionTypeEnumSchema.nullish(),
    state: StateSchema.nullish(),
    subjectToLossAggregate: z.boolean().nullish(),
    wildfireExclusion: z.boolean().nullish(),
    zipcode: z.string().nullish(),
  });
}

export function UpdateQuoteInputSchema(): z.ZodObject<Properties<UpdateQuoteInput>> {
  return z.object<Properties<UpdateQuoteInput>>({
    autodeskDataRequested: z.boolean().nullish(),
    formVendor: FormVendorSchema.nullish(),
    id: z.string(),
    indicatedAt: z.string().nullish(),
    name: z.string().nullish(),
    openspaceDataRequested: z.boolean().nullish(),
    procoreDataRequested: z.boolean().nullish(),
    quoteSelection: z.lazy(() => UpdateQuoteSelectionInputSchema().nullish()),
    reasonForTechDataAbsence: ReasonForTechDataAbsenceSchema.nullish(),
    referredToCore: z.boolean().nullish(),
    referredToTreaty: z.boolean().nullish(),
  });
}

export function UpdateQuoteSelectionInputSchema(): z.ZodObject<
  Properties<UpdateQuoteSelectionInput>
> {
  return z.object<Properties<UpdateQuoteSelectionInput>>({
    brokerCommissionOverride: z.number().nullish(),
    employersLiability: z.boolean().nullish(),
    excessOfWrap: z.number().nullish(),
    generalAggregateLimit: z.number().nullish(),
    insuredRetentionAmount: z.number().nullish(),
    policyLimit: z.number().nullish(),
    productsCompletedOperationsAggregateLimit: z.number().nullish(),
    profitLoadFeeOverride: z.number().nullish(),
    quotaShare: z.number().nullish(),
    quoteId: z.string().nullish(),
    selfAuditStatus: SelfAuditStatusOptionsSchema.nullish(),
    shepherdMgaFeesOverride: z.number().nullish(),
    stampingFeeOverride: z.number().nullish(),
    surplusLinesTaxesOverride: z.number().nullish(),
    tria: z.boolean().nullish(),
    useCredibilityWeightedPath: z.boolean().nullish(),
    uwAdjustment: z.number().nullish(),
    uwAdjustmentNotes: z.string().nullish(),
  });
}

export function UpdateQuoteStatusInputSchema(): z.ZodObject<Properties<UpdateQuoteStatusInput>> {
  return z.object<Properties<UpdateQuoteStatusInput>>({
    autodeskDataRequested: z.boolean().nullish(),
    declinedReason: DeclinedReasonSchema.nullish(),
    id: z.string(),
    indicatedLostNotes: z.string().nullish(),
    indicatedLostReason: IndicatedLostReasonSchema.nullish(),
    openspaceDataRequested: z.boolean().nullish(),
    procoreDataRequested: z.boolean().nullish(),
    quoteLostPrice: z.number().nullish(),
    quotedLostNotes: z.string().nullish(),
    quotedLostReason: QuotedLostReasonSchema.nullish(),
    reasonForTechDataAbsence: ReasonForTechDataAbsenceSchema.nullish(),
    referredToCore: z.boolean().nullish(),
    referredToTreaty: z.boolean().nullish(),
    riskTierFinal: TierEnumSchema.nullish(),
    status: QuoteStatusSchema,
  });
}

export function UpdateSelectedFormInputSchema(): z.ZodObject<Properties<UpdateSelectedFormInput>> {
  return z.object<Properties<UpdateSelectedFormInput>>({
    id: z.string(),
    uwComment: z.string(),
  });
}

export function UpdateSelectedFormsInputSchema(): z.ZodObject<
  Properties<UpdateSelectedFormsInput>
> {
  return z.object<Properties<UpdateSelectedFormsInput>>({
    formIds: z.array(z.string()),
    formVendor: FormVendorSchema.nullish(),
    quoteId: z.string(),
  });
}

export function UpdateShepsyFeedbackInputSchema(): z.ZodObject<
  Properties<UpdateShepsyFeedbackInput>
> {
  return z.object<Properties<UpdateShepsyFeedbackInput>>({
    feedback: z.boolean().nullish(),
    id: z.string(),
  });
}

export function UpdateSubjectivitiesInputSchema(): z.ZodObject<
  Properties<UpdateSubjectivitiesInput>
> {
  return z.object<Properties<UpdateSubjectivitiesInput>>({
    quoteId: z.string(),
    subjectivities: z.array(z.lazy(() => SubjectivityInputObjectSchema())),
  });
}

export function UpdateUnderlyingPolicyInputSchema(): z.ZodObject<
  Properties<UpdateUnderlyingPolicyInput>
> {
  return z.object<Properties<UpdateUnderlyingPolicyInput>>({
    attachmentOrderRank: z.number().nullish(),
    displayType: z.string().nullish(),
    effectiveDate: z
      .preprocess(
        // @ts-ignore
        (value: Date) => dayjs(value).format('YYYY-MM-DD'),
        z.string()
      )
      .nullish(),
    expirationDate: z
      .preprocess(
        // @ts-ignore
        (value: Date) => dayjs(value).format('YYYY-MM-DD'),
        z.string()
      )
      .nullish(),
    fileId: z.string().nullish(),
    id: z.string(),
    limits: z.array(z.lazy(() => UnderlyingPolicyLimitInputSchema())).nullish(),
    policyNumber: z.string().nullish(),
    quoteId: z.string().nullish(),
    type: UnderlyingPolicyTypeSchema.nullish(),
    underlyingPolicyCarrierId: z.string().nullish(),
  });
}

export function UpdateWorkersCompensationExposureInputSchema(): z.ZodObject<
  Properties<UpdateWorkersCompensationExposureInput>
> {
  return z.object<Properties<UpdateWorkersCompensationExposureInput>>({
    assessments: z.number().nullish(),
    brokerageCommissionRate: z.number().nullish(),
    grossCommissionRate: z.number().nullish(),
    policyNumber: z.string().nullish(),
    premiumPaymentTerms: TimePeriodEnumSchema.nullish(),
    totalPolicyPremium: z.number().nullish(),
    workersCompensationExposureId: z.string(),
  });
}

export function UploadAndParseLossRunFileToClaimsInputSchema(): z.ZodObject<
  Properties<UploadAndParseLossRunFileToClaimsInput>
> {
  return z.object<Properties<UploadAndParseLossRunFileToClaimsInput>>({
    carrier: z.string(),
    fileInput: z.unknown(),
    isNoLosses: z.boolean(),
    isSummary: z.boolean(),
    quoteId: z.string(),
    tpa: z.string().nullish(),
  });
}

export const UserRoleSchema = z.nativeEnum(UserRole);

export function UsersInputSchema(): z.ZodObject<Properties<UsersInput>> {
  return z.object<Properties<UsersInput>>({
    organizationIdFilter: z.string().nullish(),
    roleFilter: UserRoleSchema.nullish(),
    skip: z.number(),
    sortCreatedAt: SortOrderSchema.nullish(),
    sortEmail: SortOrderSchema.nullish(),
    sortUpdatedAt: SortOrderSchema.nullish(),
    take: z.number(),
  });
}

export function VehicleFleetInputSchema(): z.ZodObject<Properties<VehicleFleetInput>> {
  return z.object<Properties<VehicleFleetInput>>({
    count: z.number(),
    sizeClass: AutoSizeClassEnumSchema,
    state: StateSchema,
    typeOfVehicle: TypeOfVehicleEnumSchema,
  });
}

export const VehicleTypesEnumSchema = z.nativeEnum(VehicleTypesEnum);
